import React, {useEffect, useState, useRef } from "react";
import { Client } from 'paho-mqtt';
import Switch from '@mui/material/Switch'
import Bed from './Bed';
import Target from './Target';
import { useDrop } from 'react-dnd';
import arrowPurple from '../imgs/icons/arrowPurple.svg';
import modifica from '../imgs/icons/modifica.svg';
import close from '../imgs/icons/close.svg';
import confirmW from '../imgs/icons/confirmW.svg';
import sensor from "../APi/Sensor/sensor";
import add from "../imgs/icons/add.svg";
import remove from "../imgs/icons/delete.svg";
import dipasApi from '../APi/DiPas/dipasApi';
import LogDisplay from "./LogDisplay";
import GrafanaDashboard from "./Grafana";
import Grafana from "../APi/Grafana/Grafana";
const defContinua = "selettore selettoreFlex selettoreBlue";

const config = {
    "walabotConfig": {
        "maxTargetsForFallingTrigger": 0,
        "performHeatup": true,
        "performAgc": true,
        "enterDuration": 10,
        "exitDuration": 30,
        "bedExitEnabled": false,
        "bedExitPredictionThreshold": 0.9,
        "bedExitNFramesToReset": 100,
        "bedExitWallSide": "NotSet",
        "enableBedExitTelemetry": false,
        "enableBedExitStateTelemetry": false,
        "enableTrackerTargetTelemetry": true,
        "enableDoorEventTelemetry": false,
        "enablePeakTelemetry": true,
        "enableAboveThPointTelemetry": false,
        "enableIslandPointTelemetry": false,
        "enableHeightProfileTelemetry": true,
        "enableOtfPointTelemetry": true,
        "enableFallingTelemetry": true,
        "enableSensitiveFallingTelemetry": true,
        "enablePresenceTelemetry": true,
        "enableImageParamsTelemetry": true,
        "enableInterfererLocHistoryTelemetry": true,
        "enableMtiParamsTelemetry": true,
        "enableReferenceTelemetry": true,
        "enableSuiteTelemetry": false,
        "enableClustersTelemetry": true,
        "enableSubRegionStateTelemetry": true,
        "durationUntilConfirm_sec": 52,
        "minTimeOfTarInFallLoc_sec": 30,
        "dryContactActivationDuration_sec": 30,
        "fallingMitigatorEnabled": false,
        "fallingMitigatorThreshold": 0,
        "trackerSubRegions": {
        }
    }
}

const config2 = {
    "appConfig": {
        "silentMode": true,
        "ledMode": 0,
        "volume": 0,
        "logLevel": 1,
        "confirmedToAlertTimeoutSec": 40,
        "callingDurationSec": 30,
        "presenceReportMinRateMills": 60000,
        "dspRecordsPublishPolicy": false,
        "enableAnalytics": true,
        "enableTestMode": false,
        "telemetryPolicy": 2,
        "telemetryTransport": 1,
        "dryContacts": {
            "primary": {
                "mode": 1,
                "policy": 1
            },
            "secondary": {
                "mode": 1,
                "policy": 0
            }
        },
        "trackerTargetsDebugPolicy": 0,
        "demoMode": false,
        "enableDoorEvents": false,
        "enableOutOfBed": false,
        "enableSensitiveMode": false,
        "sensitivityLevel": 0.7,
        "enableBeaconScanner": false,
        "bleBeaconMacs": [],
        "thMinEventsForFirstDecision": 5,
        "thNumOfDetectionsInChain": 4,
        "checkupStatus": {
            "status": "NotTested",
            "testedInRoom": ""
        },
        "suspendDuration_sec": 900,
        "offlineMode": true,
        "enableTelemetriesOnEventDuringSuspend": true,
        "bleBeaconRssiThreshold": -80,
        "enableRssiMonitor": false,
        "rssiThresholdRssiMonitor": -70,
        "samplesNumRssiMonitor": 30
      },
    }


const montaggio = [
    {value: 0, id: "Parete"},
    {value: 1, id: "Soffitto"},
    {value: 2, id: "Soffitto 45"},
]


const ledmodes = [
    {value: 1, id: "Sempre acceso"},
    {value: 0, id: "Sempre spento"},
]

const TipoStanza = [
    {value: "none", id: "Seleziona Tipo"},
    {value: "Bedroom", id: "Camera da letto"},
    {value: "Bathroom", id: "Bagno"},
    {value: "Diningroom", id: "Soggiorno"},
    {value: "Kitchen", id: "Cucina"},
    {value: "Other", id: "Altro"},
    {value: "Passage", id: "Varco"}
]

const fallingSensitivity = [
    {value: 0, id: "Bassa"},
    {value: 1, id: "Media"},
    {value: 2, id: "Alta"},
]

const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder padding-0-9rem";
const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple padding-0-9rem selettoreShadow";


const SchedaSensore = (props) => {

    const regex = /^-?\d*\.?\d*$/;
    const [mod, setMod] = useState(0);
    const [Subregions, setSubregions] = useState(props.sensore.deviceInfo.Config.walabotConfig.trackerSubRegions);
    const [Region, setRegion] = useState(props.sensore.deviceInfo.Config.walabotConfig);
    const [stanza, setStanza] = useState(props.sensore.deviceInfo.Stanza);
    const [tipoStanza, setTipoStanza] = useState(props.sensore.deviceInfo.Tipo);
    const [piano, setPiano] = useState(props.sensore.deviceInfo.Piano);
    const [struttura, setStruttura] = useState(props.sensore.deviceInfo.Struttura);
    const [sensorMounting, setMounting] = useState(props.sensore.deviceInfo.Config.walabotConfig.sensorMounting);
    const [bedDimensions, setBedDimensions] = useState(Subregions);
    const [bedroomDimensions, setBedroomDimensions] = useState({mounting: sensorMounting, top: Region.yMax * 100 , left: Region.xMin * -100, right: Region.xMax, width: ((Region.xMin * -1) + (Region.xMax)) * 100, height: ((Region.yMin * -1)+(Region.yMax))*100});
    const [trackedtarget, setTrackedtarget] = useState([]);
    const [realtarget, setTargetreal] = useState([]);
    const [dimensions, setDimensions] = useState({Subregions, bedroomDimensions, Region, trackedtarget, realtarget});
    const [learningMode, setLearningMode] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [remainingDays, setRemainingDays] = useState(0);
    const [ledmode, setLedmode] = useState(props.sensore.deviceInfo.Config.appConfig.ledMode);
    const [silentMode, setSilentMode] = useState(props.sensore.deviceInfo.Config.appConfig.volume === 0 ? true : false);
    const [enableTestMode, setEnableTestMode] = useState(props.sensore.deviceInfo.Config.appConfig.enableTestMode);
    const [enableDemoMode, setEnableDemoMode] = useState(props.sensore.deviceInfo.Config.appConfig.demoMode);
    const [status, setStatus] = useState(0);

    const [tabs, setTabs] = useState(0);

    const [FallingSensitivity, setFallingSensitivity] = useState(props.sensore.deviceInfo.Config.walabotConfig.fallingSensitivity);
    const [ListaStrutture, setListaStrutture] = useState([]);
    const [ListaPiani, setListaPiani] = useState([]);
    const [alert, setAlert] = useState(false);
    const [xminAlert, setXminAlert] = useState(false);
    const [xmaxAlert, setXmaxAlert] = useState(false);
    const [yminAlert, setYminAlert] = useState(false);
    const [ymaxAlert, setYmaxAlert] = useState(false);
    const [zminAlert, setZminAlert] = useState(false);
    const [zmaxAlert, setZmaxAlert] = useState(false);
    const [x1Alert, setX1Alert] = useState(false);
    const [x2Alert, setX2Alert] = useState(false);
    const [y1Alert, setY1Alert] = useState(false);
    const [y2Alert, setY2Alert] = useState(false);
    const [z1Alert, setZ1Alert] = useState(false);
    const [z2Alert, setZ2Alert] = useState(false);
    const [xminRegionAlert, setXminRegionAlert] = useState(false);
    const [xmaxRegionAlert, setXmaxRegionAlert] = useState(false);
    const [yminRegionAlert, setYminRegionAlert] = useState(false);
    const [ymaxRegionAlert, setYmaxRegionAlert] = useState(false);
    const [zminRegionAlert, setZminRegionAlert] = useState(false);
    const [zmaxRegionAlert, setZmaxRegionAlert] = useState(false);
    const [mqttclient, setMqttclient] = useState();
    const [dimensionPX, setDimensionPX] = useState([]);
    const [dimensionPXoriginal, setDimensionPXoriginal] = useState([]);
    const [maxTargetsForFallingTrigger, setMaxTargetsForFallingTrigger] = useState(props.sensore.deviceInfo.Config.walabotConfig.maxTargetsForFallingTrigger);
    const [performHeatup, setPerformHeatup] = useState(props.sensore.deviceInfo.Config.walabotConfig.performHeatup);
    const [performAgc, setPerformAgc] = useState(props.sensore.deviceInfo.Config.walabotConfig.performAgc);
    const [bedExitEnabled, setBedExitEnabled] = useState(props.sensore.deviceInfo.Config.walabotConfig.bedExitEnabled);
    const [bedExitPredictionThreshold, setBedExitPredictionThreshold] = useState(props.sensore.deviceInfo.Config.walabotConfig.bedExitPredictionThreshold);
    const [bedExitNFramesToReset, setBedExitNFramesToReset] = useState(props.sensore.deviceInfo.Config.walabotConfig.bedExitNFramesToReset);
    const [bedExitWallSide, setBedExitWallSide] = useState(props.sensore.deviceInfo.Config.walabotConfig.bedExitWallSide);
    const [enableBedExitTelemetry, setEnableBedExitTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableBedExitTelemetry);
    const [enableBedExitStateTelemetry, setEnableBedExitStateTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableBedExitStateTelemetry);
    const [enableTrackerTargetTelemetry, setEnableTrackerTargetTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableTrackerTargetTelemetry);
    const [enableDoorEventTelemetry, setEnableDoorEventTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableDoorEventTelemetry);
    const [enablePeakTelemetry, setEnablePeakTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enablePeakTelemetry);
    const [enableAboveThPointTelemetry, setEnableAboveThPointTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableAboveThPointTelemetry);
    const [enableIslandPointTelemetry, setEnableIslandPointTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableIslandPointTelemetry);
    const [enableHeightProfileTelemetry, setEnableHeightProfileTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableHeightProfileTelemetry);
    const [enableOtfPointTelemetry, setEnableOtfPointTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableOtfPointTelemetry);
    const [enableFallingTelemetry, setEnableFallingTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableFallingTelemetry);
    const [enableSensitiveFallingTelemetry, setEnableSensitiveFallingTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableSensitiveFallingTelemetry);
    const [enablePresenceTelemetry, setEnablePresenceTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enablePresenceTelemetry);
    const [enableImageParamsTelemetry, setEnableImageParamsTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableImageParamsTelemetry);
    const [enableInterfererLocHistoryTelemetry, setEnableInterfererLocHistoryTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableInterfererLocHistoryTelemetry);
    const [enableMtiParamsTelemetry, setEnableMtiParamsTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableMtiParamsTelemetry);
    const [enableReferenceTelemetry, setEnableReferenceTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableReferenceTelemetry);
    const [enableSuiteTelemetry, setEnableSuiteTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableSuiteTelemetry);
    const [enableClustersTelemetry, setEnableClustersTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableClustersTelemetry);
    const [enableSubRegionStateTelemetry, setEnableSubRegionStateTelemetry] = useState(props.sensore.deviceInfo.Config.walabotConfig.enableSubRegionStateTelemetry);
    const [durationUntilConfirm_sec, setDurationUntilConfirm_sec] = useState(props.sensore.deviceInfo.Config.walabotConfig.durationUntilConfirm_sec);
    const [minTimeOfTarInFallLoc_sec, setMinTimeOfTarInFallLoc_sec] = useState(props.sensore.deviceInfo.Config.walabotConfig.minTimeOfTarInFallLoc_sec);
    const [dryContactActivationDuration_sec, setDryContactActivationDuration_sec] = useState(props.sensore.deviceInfo.Config.walabotConfig.dryContactActivationDuration_sec);
    const [fallingMitigatorEnabled, setFallingMitigatorEnabled] = useState(props.sensore.deviceInfo.Config.walabotConfig.fallingMitigatorEnabled);
    const [fallingMitigatorThreshold, setFallingMitigatorThreshold] = useState(props.sensore.deviceInfo.Config.walabotConfig.fallingMitigatorThreshold);
    const [confirmedToAlertTimeoutSec, setConfirmedToAlertTimeoutSec] = useState(props.sensore.deviceInfo.Config.appConfig.confirmedToAlertTimeoutSec);
    const [callingDurationSec, setCallingDurationSec] = useState(props.sensore.deviceInfo.Config.appConfig.callingDurationSec);
    const [presenceReportMinRateMills, setPresenceReportMinRateMills] = useState(props.sensore.deviceInfo.Config.appConfig.presenceReportMinRateMills);
    const [dspRecordsPublishPolicy, setDspRecordsPublishPolicy] = useState(props.sensore.deviceInfo.Config.appConfig.dspRecordsPublishPolicy);
    const [enableAnalytics, setEnableAnalytics] = useState(props.sensore.deviceInfo.Config.appConfig.enableAnalytics);
    const [telemetryPolicy, setTelemetryPolicy] = useState(props.sensore.deviceInfo.Config.appConfig.telemetryPolicy);
    const [telemetryTransport, setTelemetryTransport] = useState(props.sensore.deviceInfo.Config.appConfig.telemetryTransport);
    const [trackerTargetsDebugPolicy, setTrackerTargetsDebugPolicy] = useState(props.sensore.deviceInfo.Config.appConfig.trackerTargetsDebugPolicy);
    const [enableDoorEvents, setEnableDoorEvents] = useState(props.sensore.deviceInfo.Config.appConfig.enableDoorEvents);
    const [enableOutOfBed, setEnableOutOfBed] = useState(props.sensore.deviceInfo.Config.appConfig.enableOutOfBed);
    const [enableSensitiveMode, setEnableSensitiveMode] = useState(props.sensore.deviceInfo.Config.appConfig.enableSensitiveMode);
    const [sensitivityLevel, setSensitivityLevel] = useState(props.sensore.deviceInfo.Config.appConfig.sensitivityLevel);
    const [enableBeaconScanner, setEnableBeaconScanner] = useState(props.sensore.deviceInfo.Config.appConfig.enableBeaconScanner);
    //const [bleBeaconMacs, setBleBeaconMacs] = useState(props.sensore.deviceInfo.Config.appConfig.bleBeaconMacs);


   /* const [bleBeaconMacs, setBleBeaconMacs] = useState(
        JSON.stringify(props.sensore.deviceInfo.Config.appConfig.bleBeaconMacs)
    );*/


    const [bleBeaconMacs, setBleBeaconMacs] = [];


    const [thMinEventsForFirstDecision, setThMinEventsForFirstDecision] = useState(props.sensore.deviceInfo.Config.appConfig.thMinEventsForFirstDecision);
    const [thNumOfDetectionsInChain, setThNumOfDetectionsInChain] = useState(props.sensore.deviceInfo.Config.appConfig.thNumOfDetectionsInChain);
    const [checkupStatus, setCheckupStatus] = useState(props.sensore.deviceInfo.Config.appConfig.checkupStatus);
    const [suspendDuration_sec, setSuspendDuration_sec] = useState(props.sensore.deviceInfo.Config.appConfig.suspendDuration_sec);
    const [offlineMode, setOfflineMode] = useState(props.sensore.deviceInfo.Config.appConfig.offlineMode);
    const [enableTelemetriesOnEventDuringSuspend, setEnableTelemetriesOnEventDuringSuspend] = useState(props.sensore.deviceInfo.Config.appConfig.enableTelemetriesOnEventDuringSuspend);
    const [bleBeaconRssiThreshold, setBleBeaconRssiThreshold] = useState(props.sensore.deviceInfo.Config.appConfig.bleBeaconRssiThreshold);
    const [enableRssiMonitor, setEnableRssiMonitor] = useState(props.sensore.deviceInfo.Config.appConfig.enableRssiMonitor);
    const [rssiThresholdRssiMonitor, setRssiThresholdRssiMonitor] = useState(props.sensore.deviceInfo.Config.appConfig.rssiThresholdRssiMonitor);
    const [samplesNumRssiMonitor, setSamplesNumRssiMonitor] = useState(props.sensore.deviceInfo.Config.appConfig.samplesNumRssiMonitor);
    const [folderUid, setFolderUid] = useState(props.sensore.deviceInfo.folderUid);

    const initialRegion = useRef(null);


    /*useEffect(() => {
        Copy the initial value to a variable on the first load
        initialRegion = props.sensore.deviceInfo.Config.walabotConfig;
        console.log('Initial Region:', initialRegion);
      }, []);*/


    /*useEffect(() => {
        if (props.sensore?.deviceInfo?.Config?.walabotConfig) {
          console.log('Initial Region:', props.sensore.deviceInfo.Config.walabotConfig);
    
          if (initialRegion.current === null) {
            initialRegion.current = props.sensore.deviceInfo.Config.walabotConfig;
          }
        }
      }, [props.sensore]);


    const hasSetDimensionPXoriginal = useRef(false); 

    const [reloadKey, setReloadKey] = useState(0);

    const reloadComponent = () => {


        console.log('region');
        console.log(Region);

        console.log('Subregions');
        console.log(Subregions);

        console.log(initialRegion);

        setReloadKey(prevKey => prevKey + 1); // Increment the key to force re-render
    };*/


    const token = props.token;
    // console.log(sensorMounting)
    let maxWidth = 0;
    let maxHeight = 0;
    let maxLeft = 0;
    let maxRight = 0;
    let maxTop = 0;
    let maxBottom = 0;
    if (sensorMounting != 0) {
        maxWidth = 400;
        maxLeft = 200;
        maxRight = 200;
        maxHeight = 500;
        maxTop = 250;
        maxBottom = 250;
    } else {
        maxLeft = 300;
        maxRight = 300;
        maxWidth = 500;
        maxHeight = 400;
        maxTop = 400;
    }

    // console.log(sensorMounting);
    const bedroomStyle = {
        top: `${maxTop - bedroomDimensions.top}px`, //ymin
        left: `${maxLeft - bedroomDimensions.left}px`, //xmin
        width: `${bedroomDimensions.width}px`, //xmin + xmax
        height: `${bedroomDimensions.height}px`, //ymin + ymax

    };
    const verticalLineStyle = {
        //top: `${bedroomDimensions.top}px`,
        left: `${bedroomDimensions.left}px`,
        height: `${bedroomDimensions.height}px`,

    };
    const horizontalLineStyle = {
        top: sensorMounting != 0 ? `${bedroomDimensions.top}px` : `${bedroomDimensions.height}px`,
        width: `${bedroomDimensions.width}px`,
    };


    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'bed', // Specify the type of draggable elements that can be dropped here (should match the type in the Bed component)
        drop: (item, monitor) => {
        const bedroomOffset = monitor.getDifferenceFromInitialOffset();
        if (Subregions[item.id].xMin + (bedroomOffset.x / 100) < Region.xMin || Subregions.xMax + (bedroomOffset.x / 100) > Region.xMax || Subregions[item.id].yMin - (bedroomOffset.y / 100) < Region.yMin || Subregions[item.id].yMax - (bedroomOffset.y / 100) > Region.yMax) {
            return
        }
        Subregions[item.id].xMin = Subregions[item.id].xMin + (bedroomOffset.x / 100);
        Subregions[item.id].xMax = Subregions[item.id].xMax + (bedroomOffset.x / 100);
        Subregions[item.id].yMin = Subregions[item.id].yMin - (bedroomOffset.y / 100);
        Subregions[item.id].yMax = Subregions[item.id].yMax - (bedroomOffset.y / 100);

        //arrotondo i valori 2 numeri dopo la virgola
        Subregions[item.id].xMin = Math.round(Subregions[item.id].xMin * 100) / 100;

        Subregions[item.id].xMax = Math.round(Subregions[item.id].xMax * 100) / 100;
        Subregions[item.id].yMin = Math.round(Subregions[item.id].yMin * 100) / 100;
        Subregions[item.id].yMax = Math.round(Subregions[item.id].yMax * 100) / 100;
        setBedDimensions(Subregions);
        let dimension = [];
        console.log('gdddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd')
        console.log(Subregions)

        for (let i = 0; i < Subregions.length; i++) {
            dimension[i] = {
                width : ((Subregions[i].xMin * -1) + (Subregions[i].xMax)) * 100,
                height : ((Subregions[i].yMin * -1)+(Subregions[i].yMax))*100,
                depth : 150,
                marginLeft : ((Region.xMin - Subregions[i].xMin) * -1) * 100,
                marginTop : (Region.yMax - Subregions[i].yMax) * 100,
                name: Subregions[i].name,
            }
        }

        console.log('trumppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp');
        setDimensionPX(dimension);
        },
    });
    
    async function mqttDisconnect() {
        mqttclient.disconnect();
    }

    async function getlistaPiani(ListaStrutture) {
        try {
        // console.log(struttura);
        // console.log(ListaStrutture);

        for (let i = 0; i < ListaStrutture.length; i++) {
            // console.log(ListaStrutture[i]);
            if (ListaStrutture[i].name === struttura) {

            //console.log(ListaStrutture[i].id);
            await dipasApi.getPiani(token, ListaStrutture[i].id).then((piani) => {
            const arrayPiani = [];
            for (let i = 0; i < piani.length; i++) {
            arrayPiani.push({nome: piani[i].descrizione, id: piani[i].id});
                }
                // console.log(arrayPiani);
                setListaPiani(arrayPiani);
                });
            }
        }} catch(error) {
            console.log(error);
        }
    }


    async function getlistaStrutture() {
        try {
        await dipasApi.getStrutture(token).then((strutture) => {
            setListaStrutture(strutture);
            // console.log(strutture);
            getlistaPiani(strutture);
        });
        } catch(error) {
            console.log(error);
        }
    }

    async function handleChange()  {
        //setIsChecked(!isChecked);
        //console.log(isChecked);
        if (isChecked === false) {
        const result = {
            learningModeStartTs: Date.now(),
            learningModeEndTs: Date.now() + 14 * 1000 * 60 * 60 * 24,
        }
        setLearningMode(result);
        const time = setRemaininDays(result.learningModeEndTs);
            setRemainingDays(time);
        } else {
            const result = {
                learningModeStartTs: null,
                learningModeEndTs: null,
            }
            setLearningMode(result);
            const time = setRemaininDays("/");
            setRemainingDays(time);
        }
    };
    async function changeSilentMode() {
        setSilentMode(!silentMode);
    }

    async function changeEnableTestMode() {
        setEnableTestMode(!enableTestMode);
    }
    async function changeEnableDemoMode() {
        setEnableDemoMode(!enableDemoMode);
    }

    async function subscribeTotargetTopic() {
        let client = await new Client(process.env.REACT_APP_MQTT_URL, parseInt(process.env.REACT_APP_MQTT_PORT), "web_" + parseInt(Math.random() * 100, 10));
        let boolSSL = (/true/).test(process.env.REACT_APP_MQTT_SSL);
        await client.connect({
            password: process.env.REACT_APP_MQTT_PASSWORD,
            userName: process.env.REACT_APP_MQTT_USER,
            useSSL: boolSSL,
            onSuccess: () => {
                //console.log("connected");
                // Now that we are connected, subscribe to topics here
                client.subscribe("/devices/+/dashboard", {
                    onSuccess: () => {
                        client.subscribe("/devices/" + props.sensore.deviceInfo.deviceId + "/dashboard/eventTarget");
                        client.onMessageArrived = (message) => {
                            if (message.destinationName === "/devices/" + props.sensore.deviceInfo.deviceId + "/dashboard/eventTarget") {
                                //refresh target component
                                // console.log(message.payloadString);
                                const data = JSON.parse(message.payloadString);
                                //per ogni oggetto che trovo in trackedtarget analizzo se i valori non sono nulli o indefiniti
                                // console.log(data);
                                // se l'array è vuoto mando undefined
                                console.log("convert position subscribeTotargetTopic");
                                console.log(data);
                                convertPosition(data);
                            }
                        }
                    },
                    onFailure: (e) => {
                        //console.log("Subscription failed", e);
                    }
                });
            },
            onFailure: (e) => {
                //console.log("connect failed", e);
            }
        });
        setMqttclient(client);
    }

    async function convertPosition(data) {
        console.log("convertPosition");
        setTargetreal(data);
        var tosend = [];

        if (data === undefined || data.length === 0) {
            setTrackedtarget(undefined);
        } else {
            for (let key of data) {
                var xPosCm;
                var yPosCm;
                if (key.xPosCm !== null && key.xPosCm !== undefined && key.yPosCm !== null && key.yPosCm !== undefined) {
                    if (key.xPosCm < 0) {

                        xPosCm =((Region.xMin* 100) * -1) - (key.xPosCm * -1) -30;
                    } else {
                        xPosCm = (key.xPosCm) + (Region.xMin* 100 * -1) -30;
                    }
                    if (key.yPosCm < 0) {
                        yPosCm = (key.yPosCm * -1) + ((Region.yMax* 100)) -30;
                    } else {
                        yPosCm = (Region.yMax * 100) - key.yPosCm -30;
                    }
                    //console.log(key.xPosCm, key.yPosCm);
                } else {
                    xPosCm = undefined;
                    yPosCm = undefined;
                }
                tosend.push({xPosCm: xPosCm, yPosCm: yPosCm});

            }
            console.log(data);
            setTrackedtarget(tosend);
            setDimensions({Subregions, bedroomDimensions, Region, trackedtarget: tosend, realtarget: data});
        }

        let dimension = [];

        for (let i = 0; i < Subregions.length; i++) {
            dimension[i] = {
                width : ((Subregions[i].xMin * -1) + (Subregions[i].xMax)) * 100,
                height : ((Subregions[i].yMin * -1)+(Subregions[i].yMax))*100,
                depth : 150,
                marginLeft : ((Region.xMin - Subregions[i].xMin) * -1) * 100,
                marginTop : (Region.yMax - Subregions[i].yMax) * 100,
                name: Subregions[i].name,
            }
        }
        setDimensionPX(dimension);
    }

    useEffect(() => {
        (async () => {
            await getlistaStrutture();
            await setData();
            await subscribeTotargetTopic();
            sensor.getSensor(props.token, props.sensore.deviceInfo.deviceId).then((data) => {
            console.log("convert position");
            console.log(data)
            convertPosition(data.eventTarget);
            });
        }
        )();
    }, []);


    /*const fetchSensorData = async () => {
        try {
            await getlistaStrutture();
            await setData();
            await subscribeTotargetTopic();
            
            const data = await sensor.getSensor(props.token, props.sensore.deviceInfo.deviceId);
            console.log("convert position");
            console.log(data);
            convertPosition(data.eventTarget);
        } catch (error) {
            console.error("Error fetching sensor data:", error);
        }
    };

    // Use in useEffect
    useEffect(() => {
        fetchSensorData();
    }, []);

    // Button click handler
    const handleRefresh = () => {

        setDimensionPX(dimensionPXoriginal);
        
        fetchSensorData();

    };*/
    
    



    async function setData() {
        console.log("setData");
        setSubregions(props.sensore.deviceInfo.Config.walabotConfig.trackerSubRegions)
        if ("sensorMounting" in props.sensore.deviceInfo.Config.walabotConfig) {
        setMounting(props.sensore.deviceInfo.Config.walabotConfig.sensorMounting)
        }
        setRegion(props.sensore.deviceInfo.Config.walabotConfig)
        setStanza(props.sensore.deviceInfo.Stanza)
        setPiano(props.sensore.deviceInfo.Piano)
        setTipoStanza(props.sensore.deviceInfo.Tipo)
        setStruttura(props.sensore.deviceInfo.Struttura)
        //setBedroomDimensions({width: ((Region.xMin * -1) + (Region.xMax)) * 100, height: ((Region.yMin * -1)+(Region.yMax))*100})
        setBedroomDimensions({top: (Region.yMax) * 100, left: Region.xMin * -100, right: Region.xMax, bottom: Region.xMin, width: ((Region.xMin * -1) + (Region.xMax)) * 100, height: ((Region.yMin * -1)+( Region.yMax))*100})
        setBedDimensions(Subregions)

        setDimensions({Subregions, bedroomDimensions, Region });
        const time = setRemaininDays(props.sensore.deviceInfo.Config.appConfig.learningModeEndTs);
        setRemainingDays(time);
    }

    function tabConfig(id) { // Apre e chiude le tab della struttura
        let tab = document.getElementById(id);
        let arrow = document.getElementById('arrow' + id);
        if (tab.style.maxHeight === "2.6em") {
            tab.style.maxHeight = "100em";
            arrow.style.transform = "rotate(180deg)";
        }
        else {
            tab.style.maxHeight = "2.6em";
            arrow.style.transform = "rotate(0deg)";
        }
    }



    /*function tabConfig(id) {
        let tab = document.getElementById(id);
        let arrow = document.getElementById('arrow' + id);
    
        tab.style.maxHeight = "100em";
        arrow.style.transform = "rotate(180deg)";
    }*/



    
    function setRemaininDays(date) {
        //console.log(date);
        if (date === "/" || date === undefined || date === null) {
            setIsChecked(false);
            return "/";
        }else if (typeof date === "string" && isNaN(Date.parse(date))) {
            setIsChecked(false);
            return "/";
        }else {

            setIsChecked(true);
            let today = new Date();
            let end = new Date(date);
            let diff = end - today;
            let days = Math.floor(diff / (1000 * 60 * 60 * 24));
            let hours = Math.floor(diff / (1000 * 60 * 60));

            if (hours < 0) {
                return "0 giorni";
            }
            else if (days < 0) {
                return hours + " ore";
            }
            else if (days === 0) {
                return "meno di 1 giorno";
            }
            else {
                return days + " giorni";
            }
        }
    }

    const checkAlert = () => {
        console.log("checkAlert");

        if (xminAlert === true || xmaxAlert === true || yminAlert === true || ymaxAlert === true || zminAlert === true || zmaxAlert === true || x1Alert === true || x2Alert === true || y1Alert === true || y2Alert === true || z1Alert === true || z2Alert === true || xminRegionAlert === true || xmaxRegionAlert === true || yminRegionAlert === true || ymaxRegionAlert === true || zminRegionAlert === true || zmaxRegionAlert === true) {
            if (alert === false) {
                setAlert(true);
            }
        } else {
            if (alert === true) {
                setAlert(false);
            }
        }
    }

    const checkValue = (object, value) => {
        console.log("checkValue");
        let data = null;
        let message = "";
            if (object === "xMin") {
                if (parseFloat(value) >= parseFloat(-2) && value <= 0) {
                    if (xminRegionAlert === true) {
                        setXminRegionAlert(false);
                    }
                    data = false
                    message = false;
                } else {
                    if (xminRegionAlert === false) {
                        setXminRegionAlert(true);
                    }
                    data = true;
                    message = "deve essere compreso tra -2 e 0"
                }
            }
            if (object === "xMax") {
                if (parseFloat(value) >= parseFloat(0) && value <= 2) {
                    if (xmaxRegionAlert === true) {
                        setXmaxRegionAlert(false);
                    }
                    data = false
                    message = false;
                } else {
                    if (xmaxRegionAlert === false) {
                        setXmaxRegionAlert(true);
                    }
                    data = true;
                    message = "deve essere compreso tra 0 e 2"
                }
            }
            if (object === "yMin") {
                if (parseFloat(value) >= parseFloat(-3) && value <= 0) {
                    if (yminRegionAlert === true) {
                        setYminRegionAlert(false);
                    }
                    data = false
                    message = false;
                } else {
                    if (yminRegionAlert === false) {
                        setYminRegionAlert(true);
                    }
                    data = true;
                    message = "deve essere compreso tra -3 e 0"
                }

            }
            if (object === "yMax") {
                if (parseFloat(value) >= parseFloat(0) && value <= 3) {
                    if (ymaxRegionAlert === true) {
                        setYmaxRegionAlert(false);
                    }
                    data = false
                    message = false;
                } else {

                    if (ymaxRegionAlert === false) {
                        setYmaxRegionAlert(true);
                    }
                    data = true;
                    message = "deve essere compreso tra 0 e 3"

                }
            }
            if (object === "zMin") {
                if (parseFloat(value) >= parseFloat(0) && value <= 2) {
                    if (zminRegionAlert === true) {
                        setZminRegionAlert(false);
                    }
                    data = false
                    message = false;
                } else {
                    if (zminRegionAlert === false) {
                        setZminRegionAlert(true);
                    }
                    data = true;
                    message = "deve essere compreso tra 0 e 2"
                }
            }
            if (object === "zMax") {
                if (parseFloat(value) >= parseFloat(0) && value <= 2) {
                    if (zmaxRegionAlert === true) {
                        setZmaxRegionAlert(false);
                    }
                    data = false
                    message = false;
                } else {
                    if (zmaxRegionAlert === false) {
                        setZmaxRegionAlert(true);
                    }
                    data = true;
                    message = "deve essere compreso tra 0 e 2"
                }
            }
            if (object === "left") {
                if  (parseFloat(-value) + parseFloat(Region.xMax) > parseFloat(4)){
                    if (xminRegionAlert === false) {
                        setXminRegionAlert(true);
                    }
                    data = true;
                    message = "la Xtot deve essere minore di 4"
                } else if  (parseFloat(value) >= parseFloat(-3) && value <= -0.3){
                    if (xminRegionAlert === true) {
                        setXminRegionAlert(false);
                    }
                    data =  false;
                    message = false
                } else {
                    if (xminRegionAlert === false) {
                        setXminRegionAlert(true);
                    }
                    data  = true;
                    message = "deve essere compreso tra -3 e -0.3"
                }
            }
            if (object === "right") {
                if  (parseFloat(value) + parseFloat(-Region.xMin) > parseFloat(4)) {
                    if (xmaxRegionAlert === false) {
                        setXmaxRegionAlert(true);
                    }

                    data = true;
                    message = "la Xtot deve essere minore di 4"
                } else if  (parseFloat(value) <= parseFloat(2) && value >= 0) {
                    if (xmaxRegionAlert === true) {
                        setXmaxRegionAlert(false);
                    }

                    data =  false;
                    message = false
                } else {
                    if (xmaxRegionAlert === false) {
                        setXmaxRegionAlert(true);
                    }
                    data  = true;
                    message=  "deve essere compreso tra 2 e 0"
                }
            }
            if (object === "length") {
                if (parseFloat(value) >= parseFloat(0.3) && value <= 4) {
                    //setAlert(false);
                    if (ymaxRegionAlert === true) {
                        setYmaxRegionAlert(false);
                    }
                    data = false
                    message = false;
                } else {
                    if (ymaxRegionAlert === false) {
                        setYmaxRegionAlert(true);
                    }
                    data = true;
                    message = "deve essere compreso tra 0.3 e 4"
                }
            }
            checkAlert();
            return message;
    };





    /*const checkValue = (object, value, region) => {
        const rangeCheck = (val, min, max, alertSetter, alertMessage) => {
            if (parseFloat(val) >= min && parseFloat(val) <= max) {
                alertSetter(false); // No alert needed
                return null; // No error message
            } else {
                alertSetter(true); // Trigger alert
                return alertMessage; // Error message
            }
        };
    
        switch (object) {
            case "xMin":
                return rangeCheck(value, -2, 0, setXminRegionAlert, "deve essere compreso tra -2 e 0");
            case "xMax":
                return rangeCheck(value, 0, 2, setXmaxRegionAlert, "deve essere compreso tra 0 e 2");
            case "yMin":
                return rangeCheck(value, -3, 0, setYminRegionAlert, "deve essere compreso tra -3 e 0");
            case "yMax":
                return rangeCheck(value, 0, 3, setYmaxRegionAlert, "deve essere compreso tra 0 e 3");
            case "zMin":
                return rangeCheck(value, 0, 2, setZminRegionAlert, "deve essere compreso tra 0 e 2");
            case "zMax":
                return rangeCheck(value, 0, 2, setZmaxRegionAlert, "deve essere compreso tra 0 e 2");
            case "left":
                if (parseFloat(-value) + parseFloat(region.xMax) > 4) {
                    setXminRegionAlert(true);
                    return "la Xtot deve essere minore di 4";
                }
                return rangeCheck(value, -3, -0.3, setXminRegionAlert, "deve essere compreso tra -3 e -0.3");
            case "right":
                if (parseFloat(value) + parseFloat(-region.xMin) > 4) {
                    setXmaxRegionAlert(true);
                    return "la Xtot deve essere minore di 4";
                }
                return rangeCheck(value, 0, 2, setXmaxRegionAlert, "deve essere compreso tra 2 e 0");
            case "length":
                return rangeCheck(value, 0.3, 4, setYmaxRegionAlert, "deve essere compreso tra 0.3 e 4");
            default:
                return null; // No error for unhandled cases
        }
    };*/
    

    const checkSubregioValue = (object, value, index) => {

        console.log("checkSubregioValue");

        console.log("yMin Section Debugging:");

        console.log("Input value:", value);

        console.log("Region.yMin:", Region.yMin, "Region.yMax:", Region.yMax);

        console.log("Subregions[index].yMin:", Subregions[index]?.yMin, "Subregions[index].yMax:", Subregions[index]?.yMax);
        
        var message = "";

        if (object === "xMin") {
            // il valore deve essere uguale o minore di Region.xMin e non deve essere maggiore di xMax
            console.log('xmin')
            if (parseFloat(value) < parseFloat(Region.xMin)) {
                if (xminAlert === false) {
                    setXminAlert(true);
                }
                message = "deve essere minore della regione xMin";
            } else if (parseFloat(value) > parseFloat(Region.xMax)) {
                if (xminAlert === false) {
                    setXminAlert(true);
                }
                message = "deve essere minore della regione xMax";
            } else if (parseFloat(value) >= parseFloat(Subregions[index].xMax)) {
                if (xminAlert === false) {
                    setXminAlert(true);
                }
                message = "deve essere minore di xMax";
            } else {
                return false;
            }
        }

        if (object === "xMax") {
            console.log('xmax')
            if (parseFloat(value) > parseFloat(Region.xMax)) {
                if (xmaxAlert === false) {
                    setXmaxAlert(true);
                }
                message = "deve essere minore della regione xMax";
            } else if (parseFloat(value) < parseFloat(Region.xMin)) {
                if (xmaxAlert === false) {
                    setXmaxAlert(true);
                }
                message = "deve essere maggiore della regione xMin";
            } else if (parseFloat(value) <= parseFloat(Subregions[index].xMin)) {
                if (xmaxAlert === false) {
                    setXmaxAlert(true);
                }
                message = "deve essere maggiore di xMin";
            }
            else {
                message = false;
            }
        }

        if (object === "yMin") {
            console.log('ymin')
            if (parseFloat(value) < parseFloat(Region.yMin)) {
                if (yminAlert === false) {
                    setYminAlert(true);
                }
                message = "deve essere minore della regione yMin";
            } else if (parseFloat(value) > parseFloat(Region.yMax)) {
                if (yminAlert === false) {
                    setYminAlert(true);
                }
                message = "deve essere minore della regione yMax";
            } else if (parseFloat(value) >= parseFloat(Subregions[index].yMax)) {
                if (yminAlert === false) {
                    setYminAlert(true);
                }
                message = "deve essere minore di yMax";
            } else {
                if (yminAlert === true) {
                    setYminAlert(false);
                }
                message = false;
            }
        }

        if (object === "yMax") {
            console.log('ymin')
            if (parseFloat(value) > parseFloat(Region.yMax)) {
                if (ymaxAlert === false) {
                    setYmaxAlert(true);
                }
                message = "deve essere minore della regione yMax";
            } else if (parseFloat(value) < parseFloat(Region.yMin)) {
                if (ymaxAlert === false) {
                    setYmaxAlert(true);
                }
                message = "deve essere maggiore della regione yMin";
            } else if (parseFloat(value) <= parseFloat(Subregions[index].yMin)) {
                if (ymaxAlert === false) {
                    setYmaxAlert(true);
                }
                message = "deve essere maggiore di yMin";
            }
            else {
                if (ymaxAlert === true) {
                    setYmaxAlert(false);
                }
                message = false;
            }
        }

        if (object === "zMin") {
            console.log('zmin')
             if (parseFloat(value) > parseFloat(Region.zMax)) {
                if (zminAlert === false) {
                    setZminAlert(true);
                }
                message = "deve essere minore della regione zMax";
            } else if (parseFloat(value) > parseFloat(Subregions[index].zMax)) {
                if (zminAlert === false) {
                    setZminAlert(true);
                }
                message = "deve essere minore di zMax";
            }
            else {
                if (zminAlert === true) {
                    setZminAlert(false);
                }
                message = false;
            }
        }

        if (object === "zMax") {
            console.log('zmax')
            if (parseFloat(value) > parseFloat(Region.zMax)) {
                if (zmaxAlert === false) {
                    setZmaxAlert(true);
                }
                message = "deve essere minore della regione zMax";

            } else if(parseFloat(value) <= parseFloat(Subregions[index].zMin)) {
                if (zmaxAlert === false) {
                    setZmaxAlert(true);
                }
                message = "deve essere maggiore di zMin";
            } else {
                if (zmaxAlert === true) {
                    setZmaxAlert(false);
                }
                message = false;
            }
        }

        if (object === "X1") {
            // il valore deve essere uguale o minore di Region.xMin e non deve essere maggiore di xMax
            if (parseFloat(value) < parseFloat(Region.xMin)) {
                if (x1Alert === false) {
                    setX1Alert(true);
                }
                message = "deve essere minore di Sinistra";
            } else if (parseFloat(value) > parseFloat(Region.xMax)) {
                if (x1Alert === false) {
                    setX1Alert(true);
                }
                message = "deve essere minore di Destra";
            } else {
                if (x1Alert === true) {
                    setX1Alert(false);
                }
                message = false;
            }
        }

        if (object === "X2") {
            // il valore deve essere uguale o minore di Region.xMax e non deve essere minore di xMin
            if (parseFloat(value) > parseFloat(Region.xMax)) {
                if (x2Alert === false) {
                    setX2Alert(true);
                }
                message = "deve essere minore di Destra";
            } else if (parseFloat(value) < parseFloat(Region.xMin)) {
                if (x2Alert === false) {
                    setX2Alert(true);
                }
                message = "deve essere minore di Sinistra";
            } else {
                if (x2Alert === true) {
                    setX2Alert(false);
                }
                message = false;
            }
        }

        if (object === "Y1") {
            // il valore deve essere uguale o minore di Region.yMin e non deve essere maggiore di yMax
            if (parseFloat(value) < 0) {
                if (y1Alert === false) {
                    setY1Alert(true);
                }
                message = "deve essere maggiore o uguale a 0 ";
            } else if (parseFloat(value) > parseFloat(Region.yMax)) {
                if (y1Alert === false) {
                    setY1Alert(true);
                }
                message = "deve essere minore della lunghezza totale";
            } else {
                if (y1Alert === true) {
                    setY1Alert(false);
                }
                message = false;
            }
        }

        if (object === "Y2") {
            // il valore deve essere uguale o maggiore di Region.yMax e non deve essere minore di yMin
            if (parseFloat(value) > parseFloat(Region.yMax)) {
                if (y2Alert === false) {
                    setY2Alert(true);
                }
                message = "deve essere minore della lunghezza totale";
            } else if (parseFloat(value) <= 0) {
                if (y2Alert === false) {
                    setY2Alert(true);
                }
                message = "deve essere maggiore di 0";
            } else {
                if (y2Alert === true) {
                    setY2Alert(false);
                }
                message = false;
            }
        }

        if (object === "Z1") {
            // il valore deve essere uguale o minore di Region.zMin e non deve essere maggiore di zMax
            if (parseFloat(value) < 0) {
                if (z1Alert === false) {
                    setZ1Alert(true);
                }
                message = "deve essere maggiore o uguale a 0";
            } else if (parseFloat(value) >1.8) {
                if (z1Alert === false) {
                    setZ1Alert(true);
                }
                message = "deve essere minore 1.8";
            } else if (parseFloat(value) >= parseFloat(Subregions[index].zMax)) {
                if (z1Alert === false) {
                    setZ1Alert(true);
                }
                message = "deve essere minore di Z2";
            }
            else {
                if (z1Alert === true) {
                    setZ1Alert(false);
                }
                message = false;
            }
        }

        if (object === "Z2") {
            // il valore deve essere uguale o maggiore di Region.zMax e non deve essere minore di zMin
            if (parseFloat(value) > 1.8) {
                if (z2Alert === false) {
                    setZ2Alert(true);
                }
                message = "deve essere minore di 1.8";
            } else if (parseFloat(value) <= 0) {
                if (z2Alert === false) {
                    setZ2Alert(true);
                }
                message = "deve essere maggiore di 0";
            } else if (parseFloat(value) <= parseFloat(Subregions[index].zMin)) {
                if (z2Alert === false) {
                    setZ2Alert(true);
                }
                message = "deve essere maggiore di Z1";
            }
            else {
                if (z2Alert === true) {
                    setZ2Alert(false);
                }
                message = false;
            }
        }

        console.log(message);

        return message;

    }


    const setSensorMounting = (value) => {

        setMounting(value);
    }

    const setMainRegion = async (value, object) => {

        console.log("setMainRegion");

        //setta la main region
        //trasforma la virgola in un punto
        value = value.replace(",", ".");
        // console.log(typeof value);
        if (value === "-" || value === "" || value === null || value === undefined || value === "-0") {
            const newRegion = { ...Region };
            //remove " " from the string
            //console.log(value);
            newRegion[object] = value;
            setRegion(newRegion);
        }
        else if (regex.test(value)) {

            const index = value.indexOf(".")
            //console.log(index);
            // mi sposto di uno a destra se trovo un numero faccio il parseFloat altrementi no
            if (index !== -1) {
                if (regex.test(value[index + 1])) {
                    const newRegion = { ...Region };
                    //remove " " from the string
                    newRegion[object] = parseFloat(value.replace(",", "."));
                    //console.log(newRegion[object]);
                    // console.log(newRegion[object]);
                    setRegion(newRegion);
                    //allert
                    //checkValue(object, newRegion[object]);
                } else {
                    const newRegion = { ...Region };

                    //console.log(value);
                    newRegion[object] = value;
                    if (typeof newRegion[object] === String) {
                        //console.log("string");
                    }
                    setRegion(newRegion);
                }
            } else {
                const newRegion = { ...Region };
                //remove " " from the string
                //console.log(parseFloat(value));
                newRegion[object] = parseFloat(value);
                // console.log(newRegion[object]);
                //checkValue(object, newRegion[object]);
                setRegion(newRegion);
            }
        } else {
            //check if the value can be converted to a floating number
        }
    }

    const setSubregion = async (value, index, object) => {

        console.log("setSubregion");
        //trasforma la virgola in un punto

        if (object === "isFallingDetection" || object === "isPresenceDetection" || object === "isLowSnr" || object === "isDoor") {
            const newRegions = [...Subregions];
            //remove " " from the string
            // console.log(value);
            newRegions[index][object] = value;
            setSubregions(newRegions);
            // console.log(Subregions[index].isFallingDetection);
            return;
        }
        value = value.replace(",", ".");
        if (value === "-" || value === "" || value === null || value === undefined || value === "-0") {
            const newRegions = [...Subregions];
            //remove " " from the string
            //console.log(value);
            newRegions[index][object] = value;
            setSubregions(newRegions);
        }
        else if (regex.test(value)) {
            const newRegions = [...Subregions];
            //remove " " from the string

            newRegions[index][object] = value;
            setSubregions(newRegions);
            // cerco la virgola
            const index2 = value.indexOf(".")
            // mi sposto di uno a destra se trovo un numero faccio il parseFloat altrementi no
            if (index2 !== -1) {
                if (regex.test(value[index2 + 1])) {
                    const newRegions = [...Subregions];
                    //remove " " from the string
                    newRegions[index][object] = parseFloat(value.replace(",", "."));
                    //console.log(newRegions[index][object]);
                    setSubregions(newRegions);
                } else {
                    const newRegions = [...Subregions];
                    //remove " " from the string
                    //console.log(value);
                    newRegions[index][object] = value;
                    setSubregions(newRegions);
                }
            } else {
                const newRegions = [...Subregions];
                //remove " " from the string
                //console.log(parseFloat(value));
                newRegions[index][object] = parseFloat(value);
                setSubregions(newRegions);
            }
        } else {
            //check if the value can be converted to a floating number
        }
    }

    const setSubregionName = (value, index) => {
        const newRegions = [...Subregions];
        newRegions[index].name = value;
        setSubregions(newRegions);
    }

    const renderInputWithError = (measurement, label, value, onChange, wrapcheckmeasurement) => {

        console.log("renderInputWithError");

        const toCkeck = wrapcheckmeasurement || measurement;

        const isError = checkValue(toCkeck, value);
        return (

            <tr>
                <td width="10%">{label}</td>
                <td width="10%">
                    <input
                        style={isError ? { border: ".7px solid rgb(207, 19, 19)" } : null}
                        type="number"
                        name={label}
                        id={measurement}
                        value={value}
                        disabled={mod === 0}
                        onChange={(e) => onChange(e.target.value, measurement)}
                    />
                    {isError && <div className="errorBox">{checkValue(toCkeck, value)}</div>}
                </td>
            </tr>
        );
    }


    const addSubregion = () => {
        //aggiunge una subregion
        var newRegions = [];
        newRegions = [...Subregions];
        newRegions.push(
            {
                "enterDuration": 10,
                "exitDuration": 10,
                "isFallingDetection": false,
                "isPresenceDetection": true,
                "isLowSnr": true,
                "isDoor": false,
                "name": ""
            }
        );
        // console.log(newRegions);
        setSubregions(newRegions);

    }
    const removeSubregion = (index) => {
        //rimuove una subregion
        const newRegions = [...Subregions];
        newRegions.splice(index, 1);
        setSubregions(newRegions);
    }



    const salvaModifiche = async () => {
        try {
        //salva le modifiche
        setMod(0);
        Region.fallingSensitivity = FallingSensitivity
        Region.trackerSubRegions = Subregions
        Region.sensorMounting = sensorMounting
        Region.maxTargetsForFallingTrigger = maxTargetsForFallingTrigger
        Region.performHeatup = performHeatup
        Region.performAgc = performAgc
        Region.bedExitEnabled= bedExitEnabled
        Region.bedExitPredictionThreshold= bedExitPredictionThreshold
        Region.bedExitNFramesToReset= bedExitNFramesToReset
        Region.bedExitWallSide= bedExitWallSide
        Region.enableBedExitTelemetry= enableBedExitTelemetry
        Region.enableBedExitStateTelemetry= enableBedExitStateTelemetry
        Region.enableTrackerTargetTelemetry= enableTrackerTargetTelemetry
        Region.enableDoorEventTelemetry= enableDoorEventTelemetry
        Region.enablePeakTelemetry= enablePeakTelemetry
        Region.enableAboveThPointTelemetry= enableAboveThPointTelemetry
        Region.enableIslandPointTelemetry= enableIslandPointTelemetry
        Region.enableHeightProfileTelemetry= enableHeightProfileTelemetry
        Region.enableOtfPointTelemetry= enableOtfPointTelemetry
        Region.enableFallingTelemetry= enableFallingTelemetry
        Region.enableSensitiveFallingTelemetry= enableSensitiveFallingTelemetry
        Region.enablePresenceTelemetry= enablePresenceTelemetry
        Region.enableImageParamsTelemetry= enableImageParamsTelemetry
        Region.enableInterfererLocHistoryTelemetry= enableInterfererLocHistoryTelemetry
        Region.enableMtiParamsTelemetry= enableMtiParamsTelemetry
        Region.enableReferenceTelemetry= enableReferenceTelemetry
        Region.enableSuiteTelemetry= enableSuiteTelemetry
        Region.enableClustersTelemetry= enableClustersTelemetry
        Region.enableSubRegionStateTelemetry= enableSubRegionStateTelemetry
        Region.durationUntilConfirm_sec= durationUntilConfirm_sec
        Region.minTimeOfTarInFallLoc_sec= minTimeOfTarInFallLoc_sec
        Region.dryContactActivationDuration_sec= dryContactActivationDuration_sec
        Region.fallingMitigatorEnabled= fallingMitigatorEnabled
        Region.fallingMitigatorThreshold= fallingMitigatorThreshold
        Region.yMin= sensorMounting === 0 ? 0 : Region.yMin
        //Region.volume = silentMode ? 0 : 100;
        const data = {
            "deviceInfo": {
                "deviceId": props.sensore.deviceInfo.deviceId,
                "Struttura": struttura === undefined ? props.sensore.deviceInfo.Struttura : struttura,
                "Stanza": stanza === undefined ? props.sensore.deviceInfo.Stanza : stanza,
                "Piano": piano === undefined ? props.sensore.deviceInfo.Piano : piano,
                "Tipo": tipoStanza === undefined ? props.sensore.deviceInfo.Tipo : tipoStanza,
                "Config": {
                    "walabotConfig": Region,
                    "appConfig": {
                        volume: silentMode ? 0 : 100,
                        learningModeStartTs: learningMode.learningModeStartTs,
                        learningModeEndTs: learningMode.learningModeEndTs,
                        silentMode: false,
                        ledMode: ledmode,
                        enableSensitiveMode: true,
                        enableTestMode: enableTestMode,
                        telemetryEnabled: true,
                        demoMode: enableDemoMode,
                        confirmedToAlertTimeoutSec: confirmedToAlertTimeoutSec,
                        callingDurationSec: callingDurationSec,
                        presenceReportMinRateMills: presenceReportMinRateMills,
                        dspRecordsPublishPolicy: dspRecordsPublishPolicy,
                        enableAnalytics: enableAnalytics,
                        telemetryPolicy: telemetryPolicy,
                        telemetryTransport: telemetryTransport,
                        trackerTargetsDebugPolicy: trackerTargetsDebugPolicy,
                        enableDoorEvents: enableDoorEvents,
                        enableOutOfBed: enableOutOfBed,
                        // enableSensitiveMode: enableSensitiveMode,
                        sensitivityLevel: sensitivityLevel,
                        enableBeaconScanner: enableBeaconScanner,
                        //bleBeaconMacs: bleBeaconMacs,
                        thMinEventsForFirstDecision: thMinEventsForFirstDecision,
                        thNumOfDetectionsInChain: thNumOfDetectionsInChain,
                        suspendDuration_sec: suspendDuration_sec,
                        offlineMode: offlineMode,
                        enableTelemetriesOnEventDuringSuspend: enableTelemetriesOnEventDuringSuspend,
                        bleBeaconRssiThreshold: bleBeaconRssiThreshold,
                        enableRssiMonitor: enableRssiMonitor,
                        rssiThresholdRssiMonitor: rssiThresholdRssiMonitor,
                        samplesNumRssiMonitor: samplesNumRssiMonitor
                    }
                }
            }
        }
        // console.log(data);

        const response = await sensor.editSensorConfig(token, props.sensore.deviceInfo.deviceId, data);
        if (response) {
            // console.log("Modifiche effettuate");
            //console.log("Sessione scaduta");
            //props.logout()
        } else if (response === "unauthorized") {
            console.log("Sessione scaduta");
            props.login()
            //window.location.reload();

            //window.location.reload();
        } else if (response === "notfound") {
            console.log("Sensore non trovato");
        } else {
            console.log("Errore");
        }
        }catch(error) {
            console.log(error);
        }

        //window.location.reload();
    }

    const testFall = () => {
        const payload = {
            "type": 5,
            "payload": {
                "timestamp": 0,
                "statusUpdateTimestamp": 0,
                "status": "calling",
                "type": "string",
                "deviceId": "string",
                "endTimestamp": 0,
                "isSimulated": true,
                "exitReason": "string",
                "isLearning": true,
                "extra": "string",
                "isSilent": true,
                "fallLocX_cm": 0,
                "fallLocY_cm": 0,
                "fallLocZ_cm": 0,
                "tarHeightEst": 0,
                "idOfTrigger": "string"
            }
          }
        mqttclient.publish('/devices/' + props.sensore.deviceInfo.deviceId + '/events', JSON.stringify(payload));
    }

    const testPresence = (param) => {
        const payload = {
            "type": 4,
            "payload": {
                "presenceDetected": param,
                "presenceTargetType": 0,
                "roomPresenceIndication": 0,
                "timestamp": 0,
            }
        }
        mqttclient.publish('/devices/' + props.sensore.deviceInfo.deviceId + '/events', JSON.stringify(payload));

    }

    const testBed = (param) => {
        //test bed exit
        const payload = {
            "type": 4,
            "payload": {
                "presenceTargetType": 0,
                "roomPresenceIndication": 0,
                "timestamp": 0,
                "presenceRegionMap": {
                    0: param,
                }
            }
        }
        mqttclient.publish('/devices/' + props.sensore.deviceInfo.deviceId + '/events', JSON.stringify(payload));
    }


    const rebootDevice = () => {
        //reboot the device
        props.mqttclient.send('/devices/' + props.sensore.deviceInfo.deviceId + '/commands', JSON.stringify({type: "Reboot"}));
        mqttDisconnect();
        props.closePopup();
    }


    /*const [reloadKey, setReloadKey] = useState(0);

    const reloadComponent = () => {

        console.log('ciiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
        console.log(dimensionPX);
        setReloadKey((prevKey) => prevKey + 1); 

    };*/

    // const element = document.querySelector(".headerPopup");
    //
    // element.addEventListener('wheel', (event) => {
    //     event.preventDefault();
    //
    //     element.scrollBy({
    //         left: event.deltaY < 0 ? -30 : 30,
    //
    //     });
    // });



    // const MyComponent = () => {
    //     const containerRef = useRef(null);
    //
    //     useEffect(() => {
    //         const element = containerRef.current;
    //
    //         const handleWheel = (event) => {
    //             event.preventDefault();
    //             element.scrollBy({
    //                 left: event.deltaY < 0 ? -30 : 30,
    //             });
    //         };
    //
    //         element.addEventListener('wheel', handleWheel);
    //
    //         return () => {
    //             element.removeEventListener('wheel', handleWheel);
    //         };
    //     }, []);
    //
    //     return (
    //         <div id="container" ref={containerRef}>
    //             {/* Your content here */}
    //         </div>
    //     );
    // };




    //return a popup where you can modify the room size and subregion size
    return (
        <>

            <div id="Header" className="headerPopup" style={{justifyContent: "start"}}>
                <div style={{display: "flex"}}>
                    <div className={status === 0 ? selSelettore : defSelettore} onClick={() => setStatus(0)}>
                        {/*<div className="dot red medium" style={{ position: "absolute" }}></div>*/}
                        <p>Info</p>
                    </div>
                    <div className={status === 1 ? selSelettore : defSelettore} onClick={() => setStatus(1)}>
                        {/*<div className="dot red medium" style={{position: "absolute"}}></div>*/}
                        <p>Regioni / Sottoregioni</p>
                    </div>
                    {/* <div className={status === 2 ? selSelettore : defSelettore} onClick={() => setStatus(2)}>
                        <p>Sottoregioni</p>
                    </div> */}
                    <div className={status === 3 ? selSelettore : defSelettore} onClick={() => setStatus(3)}>
                        <p>Test</p>
                    </div>
                    <div className={status === 4 ? selSelettore : defSelettore} onClick={() => setStatus(4)}>
                        <p>Log</p>
                    </div>
                    <div className={status === 5 ? selSelettore : defSelettore} onClick={() => setStatus(5)}>
                        <p>Grafico Eventi</p>
                    </div>
                    <div className={status === 6 ? selSelettore : defSelettore} onClick={() => setStatus(6)}>
                        <p>Avanzate</p>
                    </div>
                </div>
            </div>


            <div id="PopupBody2">
                {status === 0 ? <>
                    <div className="table-container">
                        <table className="scheda">
                            <tbody>
                            <tr>
                                <td width="30%" style={{userSelect: "text"}}>ID Sensore</td>
                                <td width="30%" style={{userSelect: "text"}}>{props.sensore.deviceInfo.deviceId}</td>
                            </tr>
                            <tr>
                                <td width="30%" style={{userSelect: "text"}}>Seriale Sensore</td>
                                <td width="30%" style={{userSelect: "text"}}>{props.serial}</td>
                            </tr>
                            <tr>
                                <td width="30%" style={{userSelect: "text"}}>MAC</td>
                                <td width="30%" style={{userSelect: "text"}}>{props.mac}</td>
                            </tr>
                            <tr>
                                <td width="30%" style={{userSelect: "text"}}>Attenuazione</td>
                                <td width="30%" style={{userSelect: "text"}}>{props.rssi}</td>
                            </tr>
                            <tr>
                                <td width="30%">Struttura</td>
                                <td width="30%">

                                    <select type="text" name="struttura" id="struttura" value={struttura}
                                            disabled={mod === 0 ? true : false}
                                            onChange={(e) => setStruttura(e.target.value, "struttura")}>
                                        <option value="undefined">Nessuno</option>
                                        {ListaStrutture.map((struttura, index) => {
                                            return (
                                                <option value={struttura.name} key={index}>{struttura.name}</option>

                                            )

                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td width="20%">Stanza</td>
                                <td width="20%"><input type="text" name="stanza" id="stanza" value={stanza}
                                                       disabled={mod === 0 ? true : false}
                                                       onChange={(e) => setStanza(e.target.value, "stanza")}/></td>
                            </tr>
                            <tr>
                                <td width="30%">Piano</td>
                                <td width="30%">
                                    <select type="text" name="piano" id="piano"
                                            value={piano != null ? piano : "nessuno"}
                                            disabled={mod === 0 ? true : false} onChange={async (e) => {
                                        setPiano(e.target.value);
                                        await getlistaPiani();
                                    }}>
                                        <option value="undefined">Nessuno</option>
                                        {ListaPiani.map((piano, index) => {
                                            return (
                                                <option value={piano.id} key={index}>{piano.id}</option>
                                            )
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%">Tipo Stanza</td>

                                <td width="30%">
                                    <select type="text" name="TipoStanza" id="TipoStanza"
                                            value={tipoStanza != undefined ? tipoStanza : null}
                                            disabled={mod === 0 ? true : false}
                                            onChange={(e) => setTipoStanza(e.target.value)}>
                                        {TipoStanza.map((TipoStanza, index) => {
                                            return (
                                                <option value={TipoStanza.value} key={index}>{TipoStanza.id}</option>
                                            )
                                        })}
                                    </select>
                                </td>

                            </tr>
                            <tr>
                                <td width="30%">Sensibilità Caduta</td>

                                <td width="30%">
                                    <select type="text" name="Sensibilità Caduta" id="Sensibilità Caduta"
                                            disabled={mod === 0 ? true : false} value={FallingSensitivity}
                                            onChange={(e) => setFallingSensitivity(parseInt(e.target.value))}>
                                        {fallingSensitivity.map((fallingSensitivity, index) => {
                                            return (
                                                <option value={fallingSensitivity.value}
                                                        key={index}>{fallingSensitivity.id}</option>
                                            )}
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%">Montaggio</td>
                                <td width="30%">
                                    <select type="text" name="Montaggio" id="Montaggio" value={sensorMounting}
                                            disabled={mod === 0 ? true : false}
                                            onChange={(e) => setSensorMounting(parseInt(e.target.value))}>
                                        <option value="undefined">Nessuno</option>
                                        {montaggio.map((montaggio, index) => {
                                            return (
                                                <option value={montaggio.value} key={index}>{montaggio.id}</option>
                                            )
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%">Abilita Apprendimento</td>
                                <td width="30%">
                                    <Switch
                                        disabled={mod === 0 ? true : false}
                                        onClick={mod == 1 ? handleChange : null}
                                        checked={isChecked}
                                        value={isChecked}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td width="30%">Modalità Silenziosa</td>
                                <td width="30%">
                                    <Switch
                                        disabled={mod === 0 ? true : false}
                                        onClick={mod == 1 ? changeSilentMode : null}
                                        checked={silentMode}
                                        value={silentMode}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td width="30%">Modalità Test</td>
                                <td width="30%">
                                    <Switch
                                        disabled={mod === 0 ? true : false}
                                        onClick={mod == 1 ? changeEnableTestMode : null}
                                        checked={enableTestMode}
                                        value={enableTestMode}
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td width="30%">Modalità Demo</td>
                                <td width="30%">
                                    <Switch
                                        disabled={mod === 0 ? true : false}
                                        onClick={mod == 1 ? changeEnableDemoMode : null}
                                        checked={enableDemoMode}
                                        value={enableDemoMode}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td width="30%">Giorni rimanenti</td>
                                <td width="30%">{remainingDays}</td>
                            </tr>
                            <tr>
                                <td width="30%">Riavvia</td>
                                <td width="30%">
                                    <button onClick={() => rebootDevice()}>Reboot</button>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%">ledMode</td>
                                <td width="30%">
                                    <select type="text" name="ledMode" id="ledMode" value={ledmode}
                                            disabled={mod === 0 ? true : false}
                                            onChange={(e) => setLedmode(parseInt(e.target.value))}>
                                        {ledmodes.map((ledmode, index) => {
                                            return (
                                                <option value={ledmode.value} key={index}>{ledmode.id}</option>
                                            )
                                        })}
                                    </select>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="bedroom-edit-container">

                    {/* <button onClick={() => {
                        reloadComponent();
                        handleRefresh();
                    }}>Reload</button> */}


                    {/* <button onClick={reloadComponent}>Reload Component</button> */}

                    {/* <button onClick={() => this.forceUpdate()}>Force Re-render</button> */}

                        <div className="bedroom-container" style={sensorMounting !== null && sensorMounting !== NaN && sensorMounting !== "Unedfined" && sensorMounting !== "null" ? {height: `${((Region.yMin * -1) + (Region.yMax)) * 100}`} : {display: "none"}}>
                            <div className="container-line" style={{width: maxWidth, height: maxHeight}}>

                                <div ref={mod === 1 ? drop : null} style={{width: maxWidth, height: maxHeight}}>

                                    <div id="bedroom" style={sensorMounting !== undefined && sensorMounting !== "Undefined" ? bedroomStyle : {display: "none"}}>

                                        {/* { console.log('dimensionPXoriginal') }
                                        { console.log(dimensionPXoriginal) }
                                        { console.log('dimensionPX') }
                                        { console.log(dimensionPX) } */}

                                        {[...dimensionPX].reverse().map((item, i) => {
                                            const originalIndex = dimensionPX.length - 1 - i; // Calcola l'indice corrispondente per l'inversione
                                            return (
                                                <div key={originalIndex} index={originalIndex} id={originalIndex}>
                                                    <Bed id={originalIndex} index={originalIndex} {...item} />
                                                </div>
                                            );
                                        })}

                                        { console.log(bedroomStyle) }

                                        <div className="vertical-line" style={verticalLineStyle}>
                                            <div style={{
                                                position: "absolute",
                                                bottom: `${bedroomDimensions.height - 2}px`,
                                                right: "2px",
                                                textAlign: "center",
                                                fontSize: "120%"
                                            }}>
                                                Y
                                            </div>
                                        </div>

                                        <div className="horizontal-line" style={horizontalLineStyle}>
                                            <div style={{
                                                position: "absolute",
                                                left: `${bedroomDimensions.width + 10}px`,
                                                bottom: "0.1px",
                                                textAlign: "center",
                                                fontSize: "120%"
                                            }}>
                                                X
                                            </div>
                                        </div>

                                        {trackedtarget !== undefined ?
                                            trackedtarget.map((target, index) => (
                                                <Target key={index} target={target} index={index} xMin={Region.xMin}
                                                    xMax={Region.xMax} yMin={Region.yMin} yMax={Region.yMax}
                                                    real={realtarget[index]}/>
                                            ))
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <svg
                            fill="#000000"
                            height="50px"
                            width="50px"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 490.4 490.4"
                            style={{
                                marginBottom: "220px",
                                marginLeft:"40px", 
                                transform: "translate(-50%, -50%) rotate(180deg)"
                            }}
                        >
                            <g>
                                <path d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796
                                    s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z
                                    M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z"/>
                            </g>
                        </svg>
                    </div>

                    </> : status === 1 ?
                        <>

                            <div className='tabConfig region' key={props.sensore.deviceInfo.deviceId + "region"}
                                id={props.sensore.deviceInfo.deviceId + "region"} style={{maxHeight: "2.6em"}}>
                            </div>

                                {/* <table className="scheda">

                                    <h1>fsgihkhfslidjijhfsdhijofsdhijlfrshiul</h1>

                                    <tbody>
                                    {sensorMounting === "null" ?
                                        <>SETTARE MONTAGGIO SENSORE PRIMA DI MODIFICARE LE REGIONI</>
                                        : sensorMounting === 1 || sensorMounting === 2 ?
                                            <>
                                                {renderInputWithError("xMax", "Xmax", Region.xMax, setMainRegion)}
                                                {renderInputWithError("xMin", "Xmin", Region.xMin, setMainRegion)}
                                                {renderInputWithError("yMax", "Ymax", Region.yMax, setMainRegion)}
                                                {renderInputWithError("yMin", "Ymin", Region.yMin, setMainRegion)}
                                                {renderInputWithError("zMax", "Zmax", Region.zMax, setMainRegion)}
                                                {renderInputWithError("zMin", "Zmin", Region.zMin, setMainRegion)}
                                                {renderInputWithError("sensorHeight", "Altezza Sensore", Region.sensorHeight, setMainRegion)}
                                                {renderInputWithError("enterDuration", "Durata Entrata", Region.enterDuration, setMainRegion)}
                                                {renderInputWithError("exitDuration", "Durata Uscita", Region.exitDuration, setMainRegion)}
                                            </>
                                            : sensorMounting === 0 ? <>
                                                {renderInputWithError("xMax", "Destra", Region.xMax, setMainRegion, "right")}
                                                {renderInputWithError("xMin", "Sinistra", Region.xMin, setMainRegion, "left")}
                                                {renderInputWithError("yMax", "Lunghezza", Region.yMax, setMainRegion, "length")}
                                                {renderInputWithError("zMax", "Zmax", Region.zMax, setMainRegion)}
                                                {renderInputWithError("zMin", "Zmin", Region.zMin, setMainRegion)}
                                                {renderInputWithError("sensorHeight", "Altezza Sensore", Region.sensorHeight, setMainRegion)}
                                                {renderInputWithError("enterDuration", "Durata Entrata", Region.enterDuration, setMainRegion)}
                                                {renderInputWithError("exitDuration", "Durata Uscita", Region.exitDuration, setMainRegion)}
                                            </> : <>SETTARE MONTAGGIO SENSORE PRIMA DI MODIFICARE LE REGIONI</>}
                                    </tbody>
                                </table> */}

                            <div style={{ width:"50%", minHeight: "450px"}}>      
                                <div
                                    className={tabs === 0 ? selSelettore : defSelettore}
                                    onClick={() => setTabs(0)}
                                >
                                    Regioni
                                </div>
                                <div
                                    className={tabs === 1 ? selSelettore : defSelettore}
                                    onClick={() => setTabs(1)}
                                >
                                    Sottoregioni
                                </div>

                                <div>

                                {tabs === 0 ? (
                                    <>
                                        <table className="scheda" style={{ width:'90%' }}>
                                            <tbody>
                                                {sensorMounting === "null" ? (
                                                    <tr>
                                                        <td colSpan="2">
                                                            SETTARE MONTAGGIO SENSORE PRIMA DI MODIFICARE LE REGIONI
                                                        </td>
                                                    </tr>
                                                ) : sensorMounting === 1 || sensorMounting === 2 ? (
                                                    <>
                                                        {renderInputWithError("xMax", "Xmax", Region.xMax, setMainRegion)}
                                                        {renderInputWithError("xMin", "Xmin", Region.xMin, setMainRegion)}
                                                        {renderInputWithError("yMax", "Ymax", Region.yMax, setMainRegion)}
                                                        {renderInputWithError("yMin", "Ymin", Region.yMin, setMainRegion)}
                                                        {renderInputWithError("zMax", "Zmax", Region.zMax, setMainRegion)}
                                                        {renderInputWithError("zMin", "Zmin", Region.zMin, setMainRegion)}
                                                        {renderInputWithError("sensorHeight", "Altezza Sensore", Region.sensorHeight, setMainRegion)}
                                                        {renderInputWithError("enterDuration", "Durata Entrata", Region.enterDuration, setMainRegion)}
                                                        {renderInputWithError("exitDuration", "Durata Uscita", Region.exitDuration, setMainRegion)}
                                                    </>
                                                ) : sensorMounting === 0 ? (
                                                    <>
                                                        {renderInputWithError("xMax", "Destra", Region.xMax, setMainRegion, "right")}
                                                        {renderInputWithError("xMin", "Sinistra", Region.xMin, setMainRegion, "left")}
                                                        {renderInputWithError("yMax", "Lunghezza", Region.yMax, setMainRegion, "length")}
                                                        {renderInputWithError("zMax", "Zmax", Region.zMax, setMainRegion)}
                                                        {renderInputWithError("zMin", "Zmin", Region.zMin, setMainRegion)}
                                                        {renderInputWithError("sensorHeight", "Altezza Sensore", Region.sensorHeight, setMainRegion)}
                                                        {renderInputWithError("enterDuration", "Durata Entrata", Region.enterDuration, setMainRegion)}
                                                        {renderInputWithError("exitDuration", "Durata Uscita", Region.exitDuration, setMainRegion)}
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <td colSpan="2">
                                                            SETTARE MONTAGGIO SENSORE PRIMA DI MODIFICARE LE REGIONI
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </>
                                ) : (

                                    <div className='tabConfig subregions' key={props.sensore.deviceInfo.deviceId + "subregion"} id={props.sensore.deviceInfo.deviceId + "subregion"} style={{maxHeight: "100em"}}>

                                        <div className='headerStruttura' style={{background: "#CDDEFF"}}>

                                            <div className='mini-tab mini-subregion'>
                                                <div>
                                                    <img src={arrowPurple} alt='arrow'
                                                        id={"arrow" + props.sensore.deviceInfo.deviceId + "subregion"}
                                                        onClick={() => tabConfig(props.sensore.deviceInfo.deviceId + "subregion")}/>
                                                    &emsp;Sottoregione
                                                </div>
                                            </div>

                                            <div className='tabConfig subregion' id="Lista" style={{maxHeight: "2.6em"}}>
                                                {mod === 1 ?
                                                    <div className='headerStruttura'>
                                                        <div className='mini-tab add' onClick={() => addSubregion()}>
                                                            Aggiungi Sottoregione <img src={add} alt='add'/>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>

                                    {Array.isArray(Subregions) && Subregions.map((subregion, index) => {

                                        return (
                                            <div className='tabConfig subregion'
                                                key={props.sensore.deviceInfo.deviceId + "subregion" + index} id={props.sensore.deviceInfo.deviceId + "subregion" + index} 
                                                style={{ maxHeight: "100em", flex: 1, flexDirection: "row", display: "flexbox", flexWrap: "wrap"}}
                                            >
                                                <div className='headerStruttura' style={{background: "#CDDEFF"}}>
                                                    <div className='mini-tab mini-subregion'>
                                                        <div>
                                                            <img src={arrowPurple} alt='arrow'
                                                                id={"arrow" + props.sensore.deviceInfo.deviceId + "subregion" + index}
                                                                onClick={() => tabConfig(props.sensore.deviceInfo.deviceId + "subregion" + index)}/>
                                                            &emsp;{subregion.name}
                                                        </div>
                                                    </div>

                                                    {mod === 1 ?
                                                        <div className='mini-tab add' onClick={() => removeSubregion(index)}>
                                                            Rimuovi Subregion <img src={remove} alt='add'/>
                                                        </div>
                                                    : null}
                                                </div>

                                                <table className="scheda" style={{ width: "90%" }}>

                                                    <tbody>
                                                    {sensorMounting === "null" ?
                                                        <>SETTARE MONTAGGIO SENSORE PRIMA DI MODIFICARE LE REGIONI</>
                                                        : sensorMounting === 1 || sensorMounting === 2 ?
                                                            <>
                                                                <tr>
                                                                    <td width="30%">name</td>

                                                                    <td width="30%"><input type="text" name="name" id="name"
                                                                        disabled={mod === 0 ? true : false}
                                                                        value={Subregions[index].name}
                                                                        onChange={(e) => setSubregionName(e.target.value, index)}/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td width="30%">Xmax</td>
                                                                    <td width="30%">
                                                                        <input type="number"
                                                                            style={checkSubregioValue("xMax", Subregions[index].xMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                            name="xMax" id="xMax"
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].xMax}
                                                                            onChange={(e) => setSubregion(e.target.value, index, "xMax")}
                                                                            min="-4" max="4" />
                                                                        <div className="errorBox">{checkSubregioValue("xMax", Subregions[index].xMax, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Xmin</td>
                                                                    <td width="30%">
                                                                        <input type="number"
                                                                                style={checkSubregioValue("xMin", Subregions[index].xMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                name="xMin" id="xMin"
                                                                                disabled={mod === 0 ? true : false}
                                                                                value={Subregions[index].xMin}
                                                                                onChange={(e) => setSubregion(e.target.value, index, "xMin")}
                                                                                min="-4" max="4" />
                                                                        <div className="errorBox">{checkSubregioValue("xMin", Subregions[index].xMin, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Ymax</td>
                                                                    <td width="30%"><input type="number"
                                                                                            style={checkSubregioValue("yMax", Subregions[index].yMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                            name="yMax" id="yMax"
                                                                                            disabled={mod === 0 ? true : false}
                                                                                            value={Subregions[index].yMax}
                                                                                            onChange={(e) => setSubregion(e.target.value, index, "yMax")}
                                                                                            min="-4" max="4"/>
                                                                        <div className="errorBox">{checkSubregioValue("yMax", Subregions[index].yMax, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Ymin</td>
                                                                    <td width="30%"><input type="number"
                                                                                            style={checkSubregioValue("yMin", Subregions[index].yMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                            name="yMin" id="yMin"
                                                                                            disabled={mod === 0 ? true : false}
                                                                                            value={Subregions[index].yMin}
                                                                                            onChange={(e) => setSubregion(e.target.value, index, "yMin")}
                                                                                            min="-4" max="4"/>
                                                                        <div className="errorBox">{checkSubregioValue("yMin", Subregions[index].yMin, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Zmax</td>
                                                                    <td width="30%"><input type="number"
                                                                                            style={checkSubregioValue("zMax", Subregions[index].zMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                            name="zMax" id="zMax"
                                                                                            disabled={mod === 0 ? true : false}
                                                                                            value={Subregions[index].zMax}
                                                                                            onChange={(e) => setSubregion(e.target.value, index, "zMax")}
                                                                                            min="-4" max="4"/>
                                                                        <div className="errorBox">{checkSubregioValue("zMax", Subregions[index].zMax, index)}</div>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Zmin</td>
                                                                    <td width="30%"><input type="number"
                                                                                            style={checkSubregioValue("zMin", Subregions[index].zMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                            name="zMin" id="zMin"
                                                                                            disabled={mod === 0 ? true : false}
                                                                                            value={Subregions[index].zMin}
                                                                                            onChange={(e) => setSubregion(e.target.value, index, "zMin")}
                                                                                            min="-4" max="4"/>
                                                                        <div className="errorBox">{checkSubregioValue("zMin", Subregions[index].zMin, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Tempo di entrata</td>
                                                                    <td width="30%"><input type="text" name="enterDuration"
                                                                                            id="enterDuration"
                                                                                            disabled={mod === 0 ? true : false}
                                                                                            value={Subregions[index].enterDuration}
                                                                                            onChange={(e) => setSubregion(e.target.value, index, "enterDuration")}/>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Tempo di uscita</td>
                                                                    <td width="30%"><input type="number" name="exitDuration"
                                                                                            id="exitDuration"
                                                                                            disabled={mod === 0 ? true : false}
                                                                                            value={Subregions[index].exitDuration}
                                                                                            onChange={(e) => setSubregion(e.target.value, index, "exitDuration")}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Rileva Caduta</td>
                                                                    <td width="30%">
                                                                        <Switch
                                                                            checked={Subregions[index].isFallingDetection === true ? true : false}
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].isFallingDetection === true ? true : false}
                                                                            onClick={(e) => setSubregion(!Subregions[index].isFallingDetection, index, "isFallingDetection")}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Rileva Occupazione</td>

                                                                    <td width="30%">
                                                                        <Switch
                                                                            checked={Subregions[index].isPresenceDetection}
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].isPresenceDetection}
                                                                            onClick={(e) => setSubregion(!Subregions[index].isPresenceDetection, index, "isPresenceDetection")}/>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Zona riposo</td>
                                                                    <td width="30%">
                                                                        <Switch
                                                                            checked={Subregions[index].isLowSnr}
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].isLowSnr}
                                                                            onClick={(e) => setSubregion(!Subregions[index].isLowSnr, index, "isLowSnr")}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">porta</td>
                                                                    <td width="30%">
                                                                    <Switch
                                                                            checked={Subregions[index].isDoor}
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].isDoor}
                                                                            onClick={(e) => setSubregion(!Subregions[index].isDoor, index, "isDoor")}/>
                                                                    </td>
                                                                </tr>

                                                            </>
                                                            : sensorMounting === 0 ?

                                                                <>
                                                                    <tr>
                                                                        <td width="30%">name</td>

                                                                        <td width="30%"><input type="text" name="name" id="name"
                                                                                                disabled={mod === 0 ? true : false}
                                                                                                value={Subregions[index].name}
                                                                                                onChange={(e) => setSubregionName(e.target.value, index)}/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">X2</td>

                                                                        <td width="30%">
                                                                            <input
                                                                                style={checkSubregioValue("X2", Subregions[index].xMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                type="text" name="xMax" id="xMax"
                                                                                disabled={mod === 0 ? true : false}
                                                                                value={Subregions[index].xMax}
                                                                                onChange={(e) => setSubregion(e.target.value, index, "xMax")}/>
                                                                            <div className="errorBox">{checkSubregioValue("X2", Subregions[index].xMax, index)}</div>
                                                                        </td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">X1</td>
                                                                        <td width="30%">
                                                                            <input
                                                                                style={checkSubregioValue("X1", Subregions[index].xMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                type="text" name="xMin" id="xMin"
                                                                                disabled={mod === 0 ? true : false}
                                                                                value={Subregions[index].xMin}
                                                                                onChange={(e) => setSubregion(e.target.value, index, "xMin")}/>

                                                                            <div
                                                                                className="errorBox">{checkSubregioValue("X1", Subregions[index].xMin, index)}</div>

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">Y2</td>
                                                                        <td width="30%">

                                                                            <input
                                                                                style={checkSubregioValue("Y2", Subregions[index].yMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                type="text" name="yMax" id="yMax"
                                                                                disabled={mod === 0 ? true : false}
                                                                                value={Subregions[index].yMax}
                                                                                onChange={(e) => setSubregion(e.target.value, index, "yMax")}/>
                                                                            <div className="errorBox">{checkSubregioValue("Y2", Subregions[index].yMax, index)}</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">Y1</td>
                                                                        <td width="30%">
                                                                            <input
                                                                                style={checkSubregioValue("Y1", Subregions[index].yMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                type="text" name="yMin" id="yMin"
                                                                                disabled={mod === 0 ? true : false}
                                                                                value={Subregions[index].yMin}
                                                                                onChange={(e) => setSubregion(e.target.value, index, "yMin")}/>
                                                                            <div className="errorBox">{checkSubregioValue("Y1", Subregions[index].yMin, index)}</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">Z2</td>
                                                                        <td width="30%">
                                                                            <input
                                                                                style={checkSubregioValue("Z2", Subregions[index].zMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                type="text" name="zMax" id="zMax"
                                                                                disabled={mod === 0 ? true : false}
                                                                                value={Subregions[index].zMax}
                                                                                onChange={(e) => setSubregion(e.target.value, index, "zMax")}/>
                                                                            <div className="errorBox">{checkSubregioValue("Z2", Subregions[index].zMax, index)}</div>
                                                                        </td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">Z1</td>
                                                                        <td width="30%">
                                                                            <input
                                                                                style={checkSubregioValue("Z1", Subregions[index].zMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                type="text" name="zMin" id="zMin"
                                                                                disabled={mod === 0 ? true : false}
                                                                                value={Subregions[index].zMin}
                                                                                onChange={(e) => setSubregion(e.target.value, index, "zMin")}/>
                                                                            <div className="errorBox">{checkSubregioValue("Z1", Subregions[index].zMin, index)}</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">Tempo di entrata</td>
                                                                        <td width="30%"><input type="text" name="enterDuration"
                                                                                                id="enterDuration"
                                                                                                disabled={mod === 0 ? true : false}
                                                                                                value={Subregions[index].enterDuration}
                                                                                                onChange={(e) => setSubregion(e.target.value, index, "enterDuration")}/>
                                                                        </td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">Tempo di uscita</td>
                                                                        <td width="30%"><input type="text" name="exitDuration"
                                                                                                id="exitDuration"
                                                                                                disabled={mod === 0 ? true : false}
                                                                                                value={Subregions[index].exitDuration}
                                                                                                onChange={(e) => setSubregion(e.target.value, index, "exitDuration")}/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">Rileva Caduta</td>
                                                                        <td width="30%">
                                                                            <Switch
                                                                                checked={Subregions[index].isFallingDetection === true ? true : false}
                                                                                disabled={mod === 0 ? true : false}
                                                                                value={Subregions[index].isFallingDetection === true ? true : false}
                                                                                onClick={(e) => setSubregion(!Subregions[index].isFallingDetection, index, "isFallingDetection")}/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="30%">Rileva Occupazione</td>

                                                                        <td width="30%">
                                                                            <Switch
                                                                                checked={Subregions[index].isPresenceDetection}
                                                                                disabled={mod === 0 ? true : false}
                                                                                // onColor="#86d3ff"
                                                                                // onHandleColor="#2693e6"
                                                                                value={Subregions[index].isPresenceDetection}
                                                                                onClick={(e) => setSubregion(!Subregions[index].isPresenceDetection, index, "isPresenceDetection")}/>
                                                                        </td>

                                                                    </tr>
                                                                    <td width="30%">Zona riposo</td>
                                                                    <td width="30%">
                                                                        <Switch
                                                                            checked={Subregions[index].isLowSnr}
                                                                            disabled={mod === 0 ? true : false}
 
                                                                            value={Subregions[index].isLowSnr}
                                                                            onClick={(e) => setSubregion(!Subregions[index].isLowSnr, index, "isLowSnr")}/>
                                                                    </td>
                                                                </>
                                                                : <>SETTARE MONTAGGIO SENSORE PRIMA DI MODIFICARE LE REGIONI</>}

                                                    </tbody>

                                                </table>

                                                {mod === 1 ?
                                                    <div className='tabConfig subregion' id="Lista"
                                                            style={{maxHeight: "2.6em"}}>
                                                        <div className='headerStruttura'></div>
                                                    </div>
                                                : <></>}
                                        </div>
                                    )
                                })}

                                </div>
                                )}

                            </div>
                                {/* { console.log('ciaoooo') } */}
                            </div>

                            <div style={{ height: "60vh", display: "flex", alignItems: "flex-end" }}>
                                <svg
                                    fill="#000000"
                                    height="50px"
                                    width="50px"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 490.4 490.4"
                                    style={{ transform: "rotate(180deg)" }}
                                >
                                    <g>
                                        <path d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796
                                            s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z
                                            M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z"/>
                                    </g>
                                </svg>
                            </div>

                                <div className="bedroom-container" style={sensorMounting !== null && sensorMounting !== NaN && sensorMounting !== "Unedfined" && sensorMounting !== "null" ? {height: `${((Region.yMin * -1) + (Region.yMax)) * 100}`} : {display: "none"}}>

                                <div className="container-line" style={{width: maxWidth, height: maxHeight}}>

                                    <div ref={mod === 1 ? drop : null} style={{width: maxWidth, height: maxHeight}}>

                                        <div id="bedroom" style={sensorMounting !== undefined && sensorMounting !== "Undefined" ? bedroomStyle : {display: "none"}}>

                                            {/* { console.log('dimensionPXoriginal') }
                                            { console.log(dimensionPXoriginal) }
                                            { console.log('dimensionPX') }
                                            { console.log(dimensionPX) } */}

                                            {[...dimensionPX].reverse().map((item, i) => {
                                                const originalIndex = dimensionPX.length - 1 - i; // Calcola l'indice corrispondente per l'inversione
                                                return (
                                                    <div key={originalIndex} index={originalIndex} id={originalIndex}>
                                                        <Bed id={originalIndex} index={originalIndex} {...item} />
                                                    </div>
                                                );
                                            })}

                                            { console.log(bedroomStyle) }

                                            <div className="vertical-line" style={verticalLineStyle}>
                                                <div style={{
                                                    position: "absolute",
                                                    bottom: `${bedroomDimensions.height - 2}px`,
                                                    right: "2px",
                                                    textAlign: "center",
                                                    fontSize: "120%"
                                                }}>
                                                    Y
                                                </div>
                                            </div>

                                            <div className="horizontal-line" style={horizontalLineStyle}>
                                                <div style={{
                                                    position: "absolute",
                                                    left: `${bedroomDimensions.width + 10}px`,
                                                    bottom: "0.1px",
                                                    textAlign: "center",
                                                    fontSize: "120%"
                                                }}>
                                                    X
                                                </div>
                                            </div>

                                            {trackedtarget !== undefined ?
                                                trackedtarget.map((target, index) => (
                                                    <Target key={index} target={target} index={index} xMin={Region.xMin}
                                                        xMax={Region.xMax} yMin={Region.yMin} yMax={Region.yMax}
                                                        real={realtarget[index]}/>
                                                ))
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </> : status === 2 ? <>

                            <div className='tabConfig subregions' key={props.sensore.deviceInfo.deviceId + "subregion"}
                                id={props.sensore.deviceInfo.deviceId + "subregion"} style={{maxHeight: "2.6em"}}>

                                <div className='headerStruttura' style={{background: "#CDDEFF"}}>

                                    <div className='mini-tab mini-subregion'>
                                        <div>
                                            <img src={arrowPurple} alt='arrow'
                                                id={"arrow" + props.sensore.deviceInfo.deviceId + "subregion"}
                                                onClick={() => tabConfig(props.sensore.deviceInfo.deviceId + "subregion")}/>
                                            &emsp;Sottoregione
                                        </div>
                                    </div>
                                    <div className='tabConfig subregion' id="Lista" style={{maxHeight: "2.6em"}}>
                                        {mod === 1 ?
                                            <div className='headerStruttura'>
                                                <div className='mini-tab add' onClick={() => addSubregion()}>
                                                    Aggiungi Sottoregione <img src={add} alt='add'/>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>

                                {Array.isArray(Subregions) && Subregions.map((subregion, index) => {

                                    {/*{Subregions.map((subregion, index) => {*/}

                                    return (

                                        <div className='tabConfig subregion'
                                            key={props.sensore.deviceInfo.deviceId + "subregion" + index}
                                            id={props.sensore.deviceInfo.deviceId + "subregion" + index} style={{
                                            maxHeight: "2.6em", flex: 1, flexDirection: "row", display: "flexbox", flexWrap: "wrap"
                                        }}>

                                            <div className='headerStruttura' style={{background: "#CDDEFF"}}>
                                                <div className='mini-tab mini-subregion'>
                                                    <div>
                                                        <img src={arrowPurple} alt='arrow'
                                                            id={"arrow" + props.sensore.deviceInfo.deviceId + "subregion" + index}
                                                            onClick={() => tabConfig(props.sensore.deviceInfo.deviceId + "subregion" + index)}/>
                                                        &emsp;{subregion.name}
                                                    </div>
                                                </div>

                                                {mod === 1 ?
                                                    <div className='mini-tab add' onClick={() => removeSubregion(index)}>
                                                        Rimuovi Subregion <img src={remove} alt='add'/>
                                                    </div>
                                                : null}
                                            </div>

                                            <table className="scheda">

                                                <tbody>
                                                {sensorMounting === "null" ?
                                                    <>SETTARE MONTAGGIO SENSORE PRIMA DI MODIFICARE LE REGIONI</>
                                                    : sensorMounting === 1 || sensorMounting === 2 ?
                                                        <>
                                                            <tr>
                                                                <td width="30%">name</td>

                                                                <td width="30%"><input type="text" name="name" id="name"
                                                                    disabled={mod === 0 ? true : false}
                                                                    value={Subregions[index].name}
                                                                    onChange={(e) => setSubregionName(e.target.value, index)}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Xmax</td>
                                                                <td width="30%">
                                                                    <input
                                                                        type="number"
                                                                        style={checkSubregioValue("xMax", Subregions[index].xMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                        name="xMax" id="xMax"
                                                                        disabled={mod === 0 ? true : false}
                                                                        value={Subregions[index].xMax}
                                                                        onChange={(e) => setSubregion(e.target.value, index, "xMax")}
                                                                        min="-2" max="2" />
                                                                    <div className="errorBox">{checkSubregioValue("xMax", Subregions[index].xMax, index)}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Xmin</td>
                                                                <td width="30%">
                                                                    <input type="number"
                                                                        style={checkSubregioValue("xMin", Subregions[index].xMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                        name="xMin" id="xMin"
                                                                        disabled={mod === 0 ? true : false}
                                                                        value={Subregions[index].xMin}
                                                                        onChange={(e) => setSubregion(e.target.value, index, "xMin")}
                                                                        min="-4" max="4"/>
                                                                    <div className="errorBox">{checkSubregioValue("xMin", Subregions[index].xMin, index)}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Ymax</td>
                                                                <td width="30%"><input type="number"
                                                                                    style={checkSubregioValue("yMax", Subregions[index].yMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                    name="yMax" id="yMax"
                                                                                    disabled={mod === 0 ? true : false}
                                                                                    value={Subregions[index].yMax}
                                                                                    onChange={(e) => setSubregion(e.target.value, index, "yMax")}
                                                                                    min="-4" max="4"/>
                                                                    <div className="errorBox">{checkSubregioValue("yMax", Subregions[index].yMax, index)}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Ymin</td>
                                                                <td width="30%"><input type="number"
                                                                                    style={checkSubregioValue("yMin", Subregions[index].yMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                    name="yMin" id="yMin"
                                                                                    disabled={mod === 0 ? true : false}
                                                                                    value={Subregions[index].yMin}
                                                                                    onChange={(e) => setSubregion(e.target.value, index, "yMin")}
                                                                                    min="-4" max="4"/>
                                                                    <div className="errorBox">{checkSubregioValue("yMin", Subregions[index].yMin, index)}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Zmax</td>
                                                                <td width="30%"><input type="number"
                                                                                    style={checkSubregioValue("zMax", Subregions[index].zMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                    name="zMax" id="zMax"
                                                                                    disabled={mod === 0 ? true : false}
                                                                                    value={Subregions[index].zMax}
                                                                                    onChange={(e) => setSubregion(e.target.value, index, "zMax")}
                                                                                    min="-4" max="4"/>
                                                                    <div className="errorBox">{checkSubregioValue("zMax", Subregions[index].zMax, index)}</div>
                                                                </td>

                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Zmin</td>
                                                                <td width="30%"><input type="number"
                                                                                    style={checkSubregioValue("zMin", Subregions[index].zMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                                    name="zMin" id="zMin"
                                                                                    disabled={mod === 0 ? true : false}
                                                                                    value={Subregions[index].zMin}
                                                                                    onChange={(e) => setSubregion(e.target.value, index, "zMin")}
                                                                                    min="-4" max="4"/>
                                                                    <div className="errorBox">{checkSubregioValue("zMin", Subregions[index].zMin, index)}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Tempo di entrata</td>
                                                                <td width="30%"><input type="text" name="enterDuration"
                                                                                    id="enterDuration"
                                                                                    disabled={mod === 0 ? true : false}
                                                                                    value={Subregions[index].enterDuration}
                                                                                    onChange={(e) => setSubregion(e.target.value, index, "enterDuration")}/>
                                                                </td>

                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Tempo di uscita</td>
                                                                <td width="30%"><input type="number" name="exitDuration"
                                                                                    id="exitDuration"
                                                                                    disabled={mod === 0 ? true : false}
                                                                                    value={Subregions[index].exitDuration}
                                                                                    onChange={(e) => setSubregion(e.target.value, index, "exitDuration")}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Rileva Caduta</td>
                                                                <td width="30%">
                                                                    <Switch
                                                                        checked={Subregions[index].isFallingDetection === true ? true : false}
                                                                        disabled={mod === 0 ? true : false}
                                                                        value={Subregions[index].isFallingDetection === true ? true : false}
                                                                        onClick={(e) => setSubregion(!Subregions[index].isFallingDetection, index, "isFallingDetection")}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Rileva Occupazione</td>
                                                                <td width="30%">
                                                                    <Switch
                                                                        checked={Subregions[index].isPresenceDetection}
                                                                        disabled={mod === 0 ? true : false}
                                                                        value={Subregions[index].isPresenceDetection}
                                                                        onClick={(e) => setSubregion(!Subregions[index].isPresenceDetection, index, "isPresenceDetection")}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">Zona riposo</td>
                                                                <td width="30%">
                                                                    <Switch
                                                                        checked={Subregions[index].isLowSnr}
                                                                        disabled={mod === 0 ? true : false}
                                                                        value={Subregions[index].isLowSnr}
                                                                        onClick={(e) => setSubregion(!Subregions[index].isLowSnr, index, "isLowSnr")}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="30%">porta</td>
                                                                <td width="30%">
                                                                <Switch
                                                                        checked={Subregions[index].isDoor}
                                                                        disabled={mod === 0 ? true : false}
                                                                        value={Subregions[index].isDoor}
                                                                        onClick={(e) => setSubregion(!Subregions[index].isDoor, index, "isDoor")}/>
                                                                </td>
                                                            </tr>
                                                        </>
                                                        : sensorMounting === 0 ?
                                                            <>
                                                                <tr>
                                                                    <td width="30%">name</td>

                                                                    <td width="30%"><input type="text" name="name" id="name"
                                                                                        disabled={mod === 0 ? true : false}
                                                                                        value={Subregions[index].name}
                                                                                        onChange={(e) => setSubregionName(e.target.value, index)}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">X2</td>

                                                                    <td width="30%">
                                                                        <input
                                                                            style={checkSubregioValue("X2", Subregions[index].xMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                            type="text" name="xMax" id="xMax"
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].xMax}
                                                                            onChange={(e) => setSubregion(e.target.value, index, "xMax")}/>
                                                                        <div className="errorBox">{checkSubregioValue("X2", Subregions[index].xMax, index)}</div>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">X1</td>
                                                                    <td width="30%">
                                                                        <input
                                                                            style={checkSubregioValue("X1", Subregions[index].xMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                            type="text" name="xMin" id="xMin"
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].xMin}
                                                                            onChange={(e) => setSubregion(e.target.value, index, "xMin")}/>

                                                                        <div
                                                                            className="errorBox">{checkSubregioValue("X1", Subregions[index].xMin, index)}</div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Y2</td>
                                                                    <td width="30%">

                                                                        <input
                                                                            style={checkSubregioValue("Y2", Subregions[index].yMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                            type="text" name="yMax" id="yMax"
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].yMax}
                                                                            onChange={(e) => setSubregion(e.target.value, index, "yMax")}/>
                                                                        <div className="errorBox">{checkSubregioValue("Y2", Subregions[index].yMax, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Y1</td>
                                                                    <td width="30%">
                                                                        <input
                                                                            style={checkSubregioValue("Y1", Subregions[index].yMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                            type="text" name="yMin" id="yMin"
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].yMin}
                                                                            onChange={(e) => setSubregion(e.target.value, index, "yMin")}/>
                                                                        <div className="errorBox">{checkSubregioValue("Y1", Subregions[index].yMin, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Z2</td>
                                                                    <td width="30%">
                                                                        <input
                                                                            style={checkSubregioValue("Z2", Subregions[index].zMax, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                            type="text" name="zMax" id="zMax"
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].zMax}
                                                                            onChange={(e) => setSubregion(e.target.value, index, "zMax")}/>
                                                                        <div className="errorBox">{checkSubregioValue("Z2", Subregions[index].zMax, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Z1</td>
                                                                    <td width="30%">
                                                                        <input
                                                                            style={checkSubregioValue("Z1", Subregions[index].zMin, index) !== false ? {border: ".7px solid rgb(207, 19, 19)"} : null}
                                                                            type="text" name="zMin" id="zMin"
                                                                            disabled={mod === 0 ? true : false}
                                                                            value={Subregions[index].zMin}
                                                                            onChange={(e) => setSubregion(e.target.value, index, "zMin")}/>
                                                                        <div className="errorBox">{checkSubregioValue("Z1", Subregions[index].zMin, index)}</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Tempo di entrata</td>
                                                                    <td width="30%"><input type="text" name="enterDuration"
                                                                                        id="enterDuration"
                                                                                        disabled={mod === 0 ? true : false}
                                                                                        value={Subregions[index].enterDuration}
                                                                                        onChange={(e) => setSubregion(e.target.value, index, "enterDuration")}/>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Tempo di uscita</td>
                                                                    <td width="30%"><input type="text" name="exitDuration"
                                                                                        id="exitDuration"
                                                                                        disabled={mod === 0 ? true : false}
                                                                                        value={Subregions[index].exitDuration}
                                                                                        onChange={(e) => setSubregion(e.target.value, index, "exitDuration")}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Rileva Caduta</td>
                                                                    <td width="30%">
                                                                        <Switch
                                                                            checked={Subregions[index].isFallingDetection === true ? true : false}
                                                                            disabled={mod === 0 ? true : false}
 
                                                                            value={Subregions[index].isFallingDetection === true ? true : false}
                                                                            onClick={(e) => setSubregion(!Subregions[index].isFallingDetection, index, "isFallingDetection")}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="30%">Rileva Occupazione</td>

                                                                    <td width="30%">
                                                                        <Switch
                                                                            checked={Subregions[index].isPresenceDetection}
                                                                            disabled={mod === 0 ? true : false}
 
                                                                            value={Subregions[index].isPresenceDetection}
                                                                            onClick={(e) => setSubregion(!Subregions[index].isPresenceDetection, index, "isPresenceDetection")}/>
                                                                    </td>

                                                                </tr>
                                                                <td width="30%">Zona riposo</td>
                                                                <td width="30%">
                                                                    <Switch
                                                                        checked={Subregions[index].isLowSnr}
                                                                        disabled={mod === 0 ? true : false}
                                                                        value={Subregions[index].isLowSnr}
                                                                        onClick={(e) => setSubregion(!Subregions[index].isLowSnr, index, "isLowSnr")}/>
                                                                </td>
                                                            </>
                                                            : <>SETTARE MONTAGGIO SENSORE PRIMA DI MODIFICARE LE REGIONI</>}

                                                </tbody>

                                            </table>

                                            {mod === 1 ?
                                                <div className='tabConfig subregion' id="Lista"
                                                    style={{maxHeight: "2.6em"}}>
                                                    <div className='headerStruttura'></div>
                                                </div>
                                            : <></>}
                                        </div>
                                    )
                                })}

                            </div>

                        </> : status === 3 ? <>

                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "60px", marginBottom: "20px"}}>

                                <button style={{marginRight: "10px"}} onClick={() => testFall()}>Testa Caduta</button>
                                <button style={{marginRight: "10px"}} onClick={() => testPresence(true)}>Testa Presenza</button>
                                <button style={{marginRight: "10px"}} onClick={() => testBed(0)}>Testa uscita letto</button>
                                <button style={{marginRight: "10px"}} onClick={() => testBed(1)}>Testa entrata letto</button>
                                <button style={{marginRight: "10px"}} onClick={() => testPresence(false)}>Testa uscita stanza</button>

                            </div>
                        </> : status === 4 ? <>
                            <LogDisplay mqttclient={mqttclient} device={props.sensore.deviceInfo.deviceId}/>
                        </> : status === 5 ? <>

                        </> : status === 6 ? <>
                            <div className="table-container" style={{marginTop: "0px"}}>
                                <table className="scheda" style={{marginTop: "0px"}}>
                                    <tbody>
                                    <tr>
                                        <td width="30%">maxTargetsForFallingTrigger</td>
                                        <td width="30%"><input type="number" name="maxTargetsForFallingTrigger"
                                                            id="maxTargetsForFallingTrigger"
                                                            disabled={mod === 0 ? true : false}
                                                            value={maxTargetsForFallingTrigger}
                                                            onChange={(e) => setMaxTargetsForFallingTrigger(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">performHeatup</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setPerformHeatup(!performHeatup);
                                                }
                                            }}
                                            checked={performHeatup}
                                            value={performHeatup}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">performAgc</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setPerformAgc(!performAgc);
                                                }
                                            }}
                                            checked={performAgc}
                                            value={performAgc}
                                        />

                                    </tr>

                                    <tr>
                                        <td width="30%">bedExitEnabled</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setBedExitEnabled(!bedExitEnabled);
                                                }
                                            }}
                                            checked={bedExitEnabled}
                                            value={bedExitEnabled}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">bedExitPredictionThreshold</td>
                                        <td width="30%"><input type="number" name="bedExitPredictionThreshold"
                                                            id="bedExitPredictionThreshold"
                                                            disabled={mod === 0 ? true : false}
                                                            value={bedExitPredictionThreshold}
                                                            onChange={(e) => setBedExitPredictionThreshold(e.target.value)}/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td width="30%">bedExitNFramesToReset</td>
                                        <td width="30%"><input type="number" name="bedExitNFramesToReset"
                                                            id="bedExitNFramesToReset"
                                                            disabled={mod === 0 ? true : false}
                                                            value={bedExitNFramesToReset}
                                                            onChange={(e) => setBedExitNFramesToReset(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">bedExitWallSide</td>
                                        <td width="30%"><input type="text" name="bedExitWallSide" id="bedExitWallSide"
                                                            disabled={mod === 0 ? true : false} value={bedExitWallSide}
                                                            onChange={(e) => setBedExitWallSide(e.target.value)}/></td>
                                    </tr>
                                    <tr>
                                        <td width="30%">enableBedExitTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableBedExitTelemetry(!enableBedExitTelemetry);
                                                }
                                            }}
                                            checked={enableBedExitTelemetry}
                                            value={enableBedExitTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableBedExitStateTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableBedExitStateTelemetry(!enableBedExitStateTelemetry);
                                                }
                                            }}
                                            checked={enableBedExitStateTelemetry}
                                            value={enableBedExitStateTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableTrackerTargetTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableTrackerTargetTelemetry(!enableTrackerTargetTelemetry);
                                                }
                                            }}
                                            checked={enableTrackerTargetTelemetry}
                                            value={enableTrackerTargetTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableDoorEventTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableDoorEventTelemetry(!enableDoorEventTelemetry);
                                                }
                                            }}
                                            checked={enableDoorEventTelemetry}
                                            value={enableDoorEventTelemetry}
                                        />
                                    </tr>
                                    {/* look objects in walabotConfig in const config */}
                                    <tr>
                                        <td width="30%">enablePeakTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnablePeakTelemetry(!enablePeakTelemetry);
                                                }
                                            }}
                                            checked={enablePeakTelemetry}
                                            value={enablePeakTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableAboveThPointTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableAboveThPointTelemetry(!enableAboveThPointTelemetry);
                                                }
                                            }}
                                            checked={enableAboveThPointTelemetry}
                                            value={enableAboveThPointTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableIslandPointTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableIslandPointTelemetry(!enableIslandPointTelemetry);
                                                }
                                            }}
                                            checked={enableIslandPointTelemetry}
                                            value={enableIslandPointTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableHeightProfileTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableHeightProfileTelemetry(!enableHeightProfileTelemetry);
                                                }
                                            }}
                                            checked={enableHeightProfileTelemetry}
                                            value={enableHeightProfileTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableOtfPointTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableOtfPointTelemetry(!enableOtfPointTelemetry);
                                                }
                                            }}
                                            checked={enableOtfPointTelemetry}
                                            value={enableOtfPointTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableFallingTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableFallingTelemetry(!enableFallingTelemetry);
                                                }
                                            }}
                                            checked={enableFallingTelemetry}
                                            value={enableFallingTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableSensitiveFallingTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableSensitiveFallingTelemetry(!enableSensitiveFallingTelemetry);
                                                }
                                            }}
                                            checked={enableSensitiveFallingTelemetry}
                                            value={enableSensitiveFallingTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enablePresenceTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnablePresenceTelemetry(!enablePresenceTelemetry);
                                                }
                                            }}
                                            checked={enablePresenceTelemetry}
                                            value={enablePresenceTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableImageParamsTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableImageParamsTelemetry(!enableImageParamsTelemetry);
                                                }
                                            }}
                                            checked={enableImageParamsTelemetry}
                                            value={enableImageParamsTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableInterfererLocHistoryTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {

                                                if (mod === 1) {
                                                    setEnableInterfererLocHistoryTelemetry(!enableInterfererLocHistoryTelemetry);
                                                }
                                            }}
                                            checked={enableInterfererLocHistoryTelemetry}
                                            value={enableInterfererLocHistoryTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableMtiParamsTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {

                                                if (mod === 1) {
                                                    setEnableMtiParamsTelemetry(!enableMtiParamsTelemetry);
                                                }
                                            }}
                                            checked={enableMtiParamsTelemetry}
                                            value={enableMtiParamsTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableReferenceTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableReferenceTelemetry(!enableReferenceTelemetry);
                                                }
                                            }}
                                            checked={enableReferenceTelemetry}
                                            value={enableReferenceTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableClustersTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableClustersTelemetry(!enableClustersTelemetry);
                                                }
                                            }}
                                            checked={enableClustersTelemetry}
                                            value={enableClustersTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableSubRegionStateTelemetry</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableSubRegionStateTelemetry(!enableSubRegionStateTelemetry);
                                                }
                                            }}
                                            checked={enableSubRegionStateTelemetry}
                                            value={enableSubRegionStateTelemetry}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">durationUntilConfirm_sec</td>
                                        <td width="30%"><input type="number" name="durationUntilConfirm_sec"
                                                            id="durationUntilConfirm_sec"
                                                            disabled={mod === 0 ? true : false}
                                                            value={durationUntilConfirm_sec}
                                                            onChange={(e) => setDurationUntilConfirm_sec(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">minTimeOfTarInFallLoc_sec</td>
                                        <td width="30%"><input type="number" name="minTimeOfTarInFallLoc_sec"
                                                            id="minTimeOfTarInFallLoc_sec"
                                                            disabled={mod === 0 ? true : false}
                                                            value={minTimeOfTarInFallLoc_sec}
                                                            onChange={(e) => setMinTimeOfTarInFallLoc_sec(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">dryContactActivationDuration_sec</td>
                                        <td width="30%"><input type="number" name="dryContactActivationDuration_sec"
                                                            id="dryContactActivationDuration_sec"
                                                            disabled={mod === 0 ? true : false}
                                                            value={dryContactActivationDuration_sec}
                                                            onChange={(e) => setDryContactActivationDuration_sec(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">fallingMitigatorEnabled</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setFallingMitigatorEnabled(!fallingMitigatorEnabled);
                                                }
                                            }}
                                            checked={fallingMitigatorEnabled}
                                            value={fallingMitigatorEnabled}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">fallingMitigatorThreshold</td>
                                        <td width="30%"><input type="number" name="fallingMitigatorThreshold"
                                                            id="fallingMitigatorThreshold"
                                                            disabled={mod === 0 ? true : false}
                                                            value={fallingMitigatorThreshold}
                                                            onChange={(e) => setFallingMitigatorThreshold(e.target.value)}/>
                                        </td>
                                    </tr>

                                    </tbody>

                                </table>
                            </div>
                            <div className="table-container" style={{marginTop: "0px"}}>
                                <table className="scheda" style={{marginTop: "0px"}}>
                                    <tbody>
                                    <tr>
                                        <td width="30%">confirmedToAlertTimeoutSec</td>
                                        <td width="30%"><input type="number" name="confirmedToAlertTimeoutSec"
                                                            id="confirmedToAlertTimeoutSec"
                                                            disabled={mod === 0 ? true : false}
                                                            value={confirmedToAlertTimeoutSec}
                                                            onChange={(e) => setConfirmedToAlertTimeoutSec(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">callingDurationSec</td>
                                        <td width="30%"><input type="number" name="callingDurationSec"
                                                            id="callingDurationSec" disabled={mod === 0 ? true : false}
                                                            value={callingDurationSec}
                                                            onChange={(e) => setCallingDurationSec(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">presenceReportMinRateMills</td>
                                        <td width="30%"><input type="number" name="presenceReportMinRateMills"
                                                            id="presenceReportMinRateMills"
                                                            disabled={mod === 0 ? true : false}
                                                            value={presenceReportMinRateMills}
                                                            onChange={(e) => setPresenceReportMinRateMills(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">dspRecordsPublishPolicy</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setDspRecordsPublishPolicy(!dspRecordsPublishPolicy);
                                                }
                                            }}
                                            checked={dspRecordsPublishPolicy}
                                            value={dspRecordsPublishPolicy}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableAnalytics</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableAnalytics(!enableAnalytics);
                                                }
                                            }}
                                            checked={enableAnalytics}
                                            value={enableAnalytics}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">telemetryPolicy</td>
                                        <td width="30%"><input type="text" name="telemetryPolicy" id="telemetryPolicy"
                                                            disabled={mod === 0 ? true : false} value={telemetryPolicy}
                                                            onChange={(e) => setTelemetryPolicy(e.target.value)}/></td>
                                    </tr>
                                    <tr>
                                        <td width="30%">telemetryTransport</td>
                                        <td width="30%"><input type="text" name="telemetryTransport" id="telemetryTransport"
                                                            disabled={mod === 0 ? true : false}
                                                            value={telemetryTransport}
                                                            onChange={(e) => setTelemetryTransport(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">trackerTargetsDebugPolicy</td>
                                        <td width="30%"><input type="text" name="trackerTargetsDebugPolicy"
                                                            id="trackerTargetsDebugPolicy"
                                                            disabled={mod === 0 ? true : false}
                                                            value={trackerTargetsDebugPolicy}
                                                            onChange={(e) => setTrackerTargetsDebugPolicy(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">enableDoorEvents</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableDoorEvents(!enableDoorEvents);
                                                }
                                            }}
                                            checked={enableDoorEvents}
                                            value={enableDoorEvents}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableOutOfBed</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableOutOfBed(!enableOutOfBed);
                                                }
                                            }}
                                            checked={enableOutOfBed}
                                            value={enableOutOfBed}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableSensitiveMode</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}
                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableSensitiveMode(!enableSensitiveMode);
                                                }
                                            }}
                                            checked={enableSensitiveMode}
                                            value={enableSensitiveMode}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">sensitivityLevel</td>
                                        <td width="30%"><input type="number" name="sensitivityLevel" id="sensitivityLevel"
                                                            disabled={mod === 0 ? true : false} value={sensitivityLevel}
                                                            onChange={(e) => setSensitivityLevel(e.target.value)}/></td>
                                    </tr>
                                    <tr>
                                        <td width="30%">enableBeaconScanner</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableBeaconScanner(!enableBeaconScanner);
                                                }
                                            }}
                                            checked={enableBeaconScanner}
                                            value={enableBeaconScanner}
                                        />
                                    </tr>
                                    {/* <tr>
                                        <td width="30%">bleBeaconMacs</td>
                                        <td width="30%"><input type="text" name="bleBeaconMacs" id="bleBeaconMacs"
                                                            disabled={mod === 0 ? true : false} 
                                                            value={bleBeaconMacs}
                                                            onChange={(e) => setBleBeaconMacs(e.target.value)}/></td>
                                    </tr> */}
                                    <tr>
                                        <td width="30%">thMinEventsForFirstDecision</td>
                                        <td width="30%"><input type="number" name="thMinEventsForFirstDecision"
                                                            id="thMinEventsForFirstDecision"
                                                            disabled={mod === 0 ? true : false}
                                                            value={thMinEventsForFirstDecision}
                                                            onChange={(e) => setThMinEventsForFirstDecision(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">thNumOfDetectionsInChain</td>
                                        <td width="30%"><input type="number" name="thNumOfDetectionsInChain"
                                                            id="thNumOfDetectionsInChain"
                                                            disabled={mod === 0 ? true : false}
                                                            value={thNumOfDetectionsInChain}
                                                            onChange={(e) => setThNumOfDetectionsInChain(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">suspendDuration_sec</td>
                                        <td width="30%"><input type="number" name="suspendDuration_sec"
                                                            id="suspendDuration_sec" disabled={mod === 0 ? true : false}
                                                            value={suspendDuration_sec}
                                                            onChange={(e) => setSuspendDuration_sec(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">offlineMode</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setOfflineMode(!offlineMode);
                                                }
                                            }}
                                            checked={offlineMode}
                                            value={offlineMode}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">enableTelemetriesOnEventDuringSuspend</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableTelemetriesOnEventDuringSuspend(!enableTelemetriesOnEventDuringSuspend);
                                                }
                                            }}
                                            checked={enableTelemetriesOnEventDuringSuspend}
                                            value={enableTelemetriesOnEventDuringSuspend}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">bleBeaconRssiThreshold</td>
                                        <td width="30%"><input type="number" name="bleBeaconRssiThreshold"
                                                            id="bleBeaconRssiThreshold"
                                                            disabled={mod === 0 ? true : false}
                                                            value={bleBeaconRssiThreshold}
                                                            onChange={(e) => setBleBeaconRssiThreshold(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">enableRssiMonitor</td>
                                        <Switch
                                            disabled={mod === 0 ? true : false}

                                            onClick={() => {
                                                if (mod === 1) {
                                                    setEnableRssiMonitor(!enableRssiMonitor);
                                                }
                                            }
                                            }
                                            checked={enableRssiMonitor}
                                            value={enableRssiMonitor}
                                        />
                                    </tr>
                                    <tr>
                                        <td width="30%">rssiThresholdRssiMonitor</td>
                                        <td width="30%"><input type="number" name="rssiThresholdRssiMonitor"
                                                            id="rssiThresholdRssiMonitor"
                                                            disabled={mod === 0 ? true : false}
                                                            value={rssiThresholdRssiMonitor}
                                                            onChange={(e) => setRssiThresholdRssiMonitor(e.target.value)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">samplesNumRssiMonitor</td>
                                        <td width="30%"><input type="number" name="samplesNumRssiMonitor"
                                                            id="samplesNumRssiMonitor"
                                                            disabled={mod === 0 ? true : false}
                                                            value={samplesNumRssiMonitor}
                                                            onChange={(e) => setSamplesNumRssiMonitor(e.target.value)}/>
                                        </td>
                                    </tr>

                                    </tbody>

                                </table>
                            </div>

                        </> : <></>}
            </div>

            <div className="edit-container" style={{padding: "0px"}}>

                {mod === 0 ?
                    <div
                        className="selettore selettoreFlex selettoreViolet"
                        onClick={() => setMod(1)}
                    >
                        <p>Modifica</p>
                        <img src={modifica} alt='modifica' style={{width: "1em"}}/>
                    </div>
                    : <>
                        {alert === false ?
                            <div
                                className={defContinua}
                                onClick={() => salvaModifiche()}
                            >
                                <p>Salva</p>
                                <img src={confirmW} alt='confirmW'/>
                            </div>
                            : null}
                        <div className="selettore selettoreFlex selettoreViolet" 
                            onClick={async () => {
                                setMod(0);
                                setData(); // Assuming this function takes no arguments
                                // props.closePopup(); Uncomment if needed
                                
                            }}
                            >
                            <p>Annulla</p>
                            <img src={close} alt="close"/>

                        </div>
                    </>
                }
            </div>

        </>
    );
}

export default SchedaSensore;